import React from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { TfiReload } from 'react-icons/tfi';
import { useReloadMetadata } from '../hooks/useReloadMeta';
import { useNotification } from '../providers/NotificationProvider';
import { GiShipBow } from 'react-icons/gi';
import { IoPlanetSharp } from 'react-icons/io5';
import { useCollectionMeta } from '../providers/CollectionMetaProvider';

function TokenMenu({ collectionName, tokenId, isMinted=undefined }) {
    const reloadMeta = useReloadMetadata(collectionName, tokenId);
    const { notify } = useNotification();
    const { meta } = useCollectionMeta();

    const doReload = () => {
        reloadMeta()
            .then(() => {
                notify(1, 'Metadata requested! Please, reload page in a minute or two.');
            })
            .catch((err) => {
                notify(0, 'We could not reload this metadata');
            });
    }

  return (
    <div className=''>
        <div className="dropdown dropdown-end">
            <label tabIndex={0}><IoMdInformationCircleOutline className={`${isMinted === false ? "text-warning" : "text-primary"} text-xl`}/></label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                <li>
                    <button onClick={doReload} className='flex items-center'>
                        <TfiReload/>
                        <span className='ml-2'>Reload Meta</span>
                    </button>
                </li>
                <li>
                    <a target="_blank" href={`https://opensea.io/assets/ethereum/${meta[collectionName].address}/${tokenId}`}>
                        <GiShipBow/>
                        <span className='ml-2 whitespace-nowrap'>View on OpenSea</span>
                    </a>
                </li>
                {
                    isMinted === false && (
                        <li>
                            <label htmlFor={`planets-minting-${tokenId}`}>
                                <IoPlanetSharp/>
                                <span className='ml-2 whitespace-nowrap'>Mint Planet</span>
                            </label>
                        </li>
                    )
                }
            </ul>
        </div>
    </div>
  )
}

export default TokenMenu;