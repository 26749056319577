import React from 'react'

function Container({children, className, bg=true}) {
  return (
    <div className='bg-base-200 relative'>
        <div className={`scroll mining rounded-3xl bg-base-100 mx-auto p-4 overflow-visible scrollbar-thin scrollbar-track-base-200 scrollbar-thumb-primary ${className}`}>
          {children}
        </div>
    </div>
  )
}

export default Container;