import React, { useEffect, useState } from 'react';
import { useAuth } from '../providers/AuthProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import axios from 'axios';

const MyMinedOreContext = React.createContext();

export function useMinedOre() {
    return React.useContext(MyMinedOreContext);
}

export function MyMinedOreProvider({ children }) {
    const [totalOre, setTotalOre] = useLocalStorage('myTotalOre', 0);
    const { isAuthenticated } = useAuth();

    const updateMinedOre = async () => {
        if (!isAuthenticated) return;
        try {
            const {data} = await axios.get('/api/tokens/totalUnrefinedOre');
            const ore = data;
            setTotalOre(ore);
        } catch {
            // window.location.href = "/login";
        }
    }

    useEffect(() => {
        updateMinedOre();
    }, [isAuthenticated]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateMinedOre();
        }, 15000);
        return () => clearInterval(interval);
    }, [isAuthenticated]);

    return (
        <MyMinedOreContext.Provider value={{ totalOre, updateMinedOre }}>
            {children}
        </MyMinedOreContext.Provider>
    );
}