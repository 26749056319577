import React, { useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import axios from 'axios';
import { useAuth } from './AuthProvider';

const OreMultiplierContext = React.createContext();

export function useOreMultiplier() {
    return React.useContext(OreMultiplierContext);
}

export function OreMultiplierProvider({ children }) {
    const [oreMultiplier, setOreMultiplier] = useLocalStorage('oreMultiplier', {
        alien_multiplier: 0,
        planet_multiplier: 0,
        colony_multiplier: 0,
        blocks_per_day: 1,
        sun_multiplier: 0,
    });
    const { isAuthenticated } = useAuth();

    const getRewardMultiplier = async () => {
        if (!isAuthenticated) return;
        try {
            const { data } = await axios.get('/api/reward-multiplier');
            setOreMultiplier(data.data.attributes);
        }
        catch {
            // window.location.href = "/login";
        }
    };

    useEffect(() => {
        getRewardMultiplier();
    }, [isAuthenticated]);

    return (
        <OreMultiplierContext.Provider value={ oreMultiplier }>
            {children}
        </OreMultiplierContext.Provider>
    );
}