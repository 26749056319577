import React, { useRef } from 'react'

function Return404() {
  const video = useRef(null);

  const play = () => {
    video.current.volume = 0.2;
    video.current.muted = !video.current.muted;
  }

  return (
    <div>
        <video className='h-screen' ref={video} autoPlay loop muted >
          <source src="videos/rickroll.mp4" type="video/mp4" />
        </video>
        <div onClick={play} className='absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-80 flex justify-center items-center flex-col text-2xl'>
            <h1 className='font-bold text-6xl text-primary'>404</h1>
            <p className='text-primary'>Not Found</p>
            <p className='text-primary text-2xs'>(click to get rickrolled)</p>
        </div>
    </div>
  )
}

export default Return404;