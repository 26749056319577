import React from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

const EventsContext = React.createContext();

export function useEvents() {
  return React.useContext(EventsContext);
}

export function EventsProvider({ children }) {
    const [events, setEvents] = React.useState([]);
    const { isAuthenticated } = useAuth();
    
    const updateEvents = async () => {
        if (!isAuthenticated) return;
        try {
            const { data } = await axios.get('/api/events/my');
            setEvents(data);
        } catch(e) {
            // window.location.href = "/login";
        }
    };
    
    React.useEffect(() => {
        updateEvents();
    }, [isAuthenticated]);
    
    return (
        <EventsContext.Provider value={{ events, updateEvents }}>
        {children}
        </EventsContext.Provider>
    );
}