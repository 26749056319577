import React, { useEffect, useState } from 'react';
import { useArtCollectionsList, getArtCollection } from '../../hooks/useArtCollections';
import { BiErrorAlt } from 'react-icons/bi';
import config from '../../config';

export default function SelectTheme({ selectTheme }) {
    const artCollectionsList = useArtCollectionsList();
    const [themeId, setThemeId] = useState(null);
    useEffect(() => {
        if(!themeId) return;
        getArtCollection(themeId).then(selectTheme);
    }, [themeId]);

    useEffect(() => {
        if (!artCollectionsList.length) return;
        getArtCollection(1).then(selectTheme);
    }, [artCollectionsList]);

    return (
        <div className='grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6'>
            {
                artCollectionsList.map((collection) => (
                    <div key={collection.id}>
                        {
                            collection.preview_image ? 
                            <img onClick={() => setThemeId(collection.id)} className='h-16 cursor-pointer' src={`${config.api_url}${collection.preview_image.url}`} />
                            :
                            <BiErrorAlt className='h-16'/>
                        }
                    </div>
                ))
            }
        </div>
    )
}