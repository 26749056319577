export default function isSun(planet) {
    if (!planet.metadata) return false;
    return !!planet.metadata.attributes.find((ea) => {
        ea.value = ea.value.replaceAll("_", " ");
        return (
            ea.value.toUpperCase() == "SUN" ||
            ea.value.toUpperCase() == "PINK SUN" ||
            ea.value.toUpperCase() == "ULTRAVIOLET" ||
            ea.value.toUpperCase() == "DYING SUN"
        )
    });
}