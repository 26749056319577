import React from 'react'

function NumberPlate({ title, children, description, className }) {
  return (
    <div className={`stats bg-base-100 shadow ${className}`}>
  
        <div className="stat">
            <div className="stat-title">{title}</div>
            <div className="stat-value">{children}</div>
            <div className="stat-desc">{description}</div>
        </div>
    </div>
  )
}

export default NumberPlate;