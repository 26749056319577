import React from 'react';
import ipfs from '../util/ipfs';
import StakeUnstakeButton from '../components/StakeUnstakeButton';
import { BiErrorAlt } from 'react-icons/bi';
import TokenMenu from '../components/TokenMenu';
import usePlanets from '../hooks/usePlanets';
import PlanetsPredictinos from '../components/predictions/PlanetsPredictinos';
import ListPlaceholder from '../components/ListPlaceholder';
import { useReloadMetadata } from '../hooks/useReloadMeta';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function Planets() {
    const planets = usePlanets();
  return (
    <div>
      <PlanetsPredictinos numPlanets={planets.length}/>
      <div className='grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-4'>
        {
          planets.map((nft, index) => {
            return <PlanetCard nft={nft} key={index} index={index} />
          })
        }
      </div>
      {
          planets.length === 0 && (
            <ListPlaceholder
              title="No Planets Found"
            />
          )
        }
    </div>
  )
}

function PlanetCard({ nft, index }) {
  const reloadMeta = useReloadMetadata("Planets", nft.token_id);
  let meta;
  try {
    meta = nft.metadata
  } catch {
    meta = { attributes: [] };
  }

  function reloadImage() {
    reloadMeta();
    console.log("reloaded " + nft.token_id);
  }

  return (
    <div key={index} className="card bg-base-100 shadow-xl overflow-visible mt-4">
      <figure className='rounded-t-xl overflow-hidden'>
        {
          Boolean(meta) && meta.image ? <img onError={reloadImage} src={ipfs(meta.image)} /> : <ImageReloader id={nft.token_id}/>
        }
        </figure>
      <div className="card-body justify-between">
        <div className='w-full flex justify-between items-center'>
          <h2 className="card-title">
            #{nft.token_id}
            {
              !nft.isStaked && (
                <div className="tooltip" data-tip="This dude's chillin'. Send him to mine some Ore!">
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6 text-warning" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                </div>
              )
            }
          </h2>
          <TokenMenu
            collectionName="Planets"
            tokenId={nft.token_id}
          />
        </div>
        <div><strong>Holds:</strong> <span>{nft.holds.toFixed(3)} ORE</span></div>
        <div className='flex flex-wrap mb-3'>
          {
            Boolean(meta) && meta.attributes.map((attr, i) => (
              <div key={`${index}-${i}`} className="badge badge-outline m-[1px] mb-[2px] text-xs">{capitalizeFirstLetter(attr.value.toLowerCase().replaceAll('_', ' '))}</div>
            ))
          }
        </div>
        <div className="card-actions justify-start">
          <StakeUnstakeButton
            collectionName="Planets"
            tokenId={nft.token_id}
            isStaked={nft.isStaked}
          />
        </div>
      </div>
    </div>
  )
}

function ImageReloader({id}) {
  const reloadMeta = useReloadMetadata("Planets", id);
  reloadMeta();
  console.log(`Meta reloaded for ${id}`);
  return (
    <BiErrorAlt className='h-48'/>
  )
}

export default Planets