import React from 'react';
import { ListingsProvider } from '../providers/ListingsProvider';
import ListingsWrapper from '../components/ListingsWrapper';


function Listings() {
  return (
    <ListingsProvider>
      <ListingsWrapper/>
    </ListingsProvider>
  )
}

export default Listings