import React from 'react'
import { AiOutlineTwitter, AiFillInstagram } from 'react-icons/ai';
import { FaDiscord } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer items-center p-4 bg-base-200 text-neutral font-semibold text-sm">
      <div className="items-center grid-flow-col">
        <img src="/logo192.png" alt="logo" className="w-8 h-8" /> 
        <p>L.A.S.C. © 2023 - All right reserved</p>
      </div> 
      <div className="grid-flow-col gap-4 items-center md:place-self-center md:justify-self-end">
        
        <a href="https://opensea.io/collection/lonelyalienspaceclub" target="_black" rel="nofollow"><img className=' w-5' src="/images/opensea.png"/></a>
        <a href="https://twitter.com/thelonelyaliens" target="_black" rel="nofollow"><AiOutlineTwitter className='text-2xl'/></a>
        <a href="https://discord.com/invite/tdxgjkUSwb" target="_black" rel="nofollow"><FaDiscord className='text-2xl'/></a>
        <a href="https://www.instagram.com/thelonelyaliens/" target="_black" rel="nofollow"><AiFillInstagram className='text-2xl'/></a>
        
      </div>
    </footer>
  )
}

export default Footer;