import React, { useState } from 'react'
import { stake, unstake } from '../util/staking';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import { useAuth } from '../providers/AuthProvider';
import { useStaked } from '../providers/StakedOreProvider';
import { useNotification } from '../providers/NotificationProvider';

function StakeUnstakeButton({ collectionName, tokenId, isStaked }) {
  const [ isLoading, setIsLoading ] = useState(false);
  const { reloadBalance: reloadAlienBalance } = useAlienBalance();
  const { reloadBalance: reloadPlanetBalance } = usePlanetBalance();
  const { updateProfile } = useAuth();
  const { updateStaked } = useStaked();
  const { notify } = useNotification();
  
  const stakeToken = () => {
    setIsLoading(true);
    stake(collectionName, tokenId)
      .then(() => {
        collectionName === 'Aliens'
          ? reloadAlienBalance()
          : reloadPlanetBalance();
        updateStaked();
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 425) {
          const message = e.response.data.error.message;
          notify("error", message);
        } else {
          notify('error', 'Something went wrong');
        }
        setIsLoading(false);
      });
  }

  const unstakeToken = () => {
    setIsLoading(true);
    unstake(collectionName, tokenId)
      .then(() => {
        updateProfile();
        collectionName === 'Aliens'
          ? reloadAlienBalance()
          : reloadPlanetBalance();
        updateStaked();
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }
  
  if (isLoading) return <button className="btn btn-sm" disabled>Waiting</button>

  return (
    <div>
        {
        isStaked ?
          <button
          onClick={unstakeToken}
            className="btn btn-active btn-ghost"
          >{collectionName === "Aliens" ? "Unstake" : "Close Border"}</button>
          :
          <button
            onClick={stakeToken}
            className="btn btn-success"
          >{collectionName === "Aliens" ? "Mine Ore" : "Open Border"}</button>
      }
    </div>
  )
}

export default StakeUnstakeButton;