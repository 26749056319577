import { useState, useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';
import axios from 'axios';

export function useArtCollectionsList() {
    const [artCollectionsList, setArtCollectionsList] = useState([]);
    const [build, setBuild] = useState(false);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        setBuild(true);
    }, [])

    useEffect(() => {
        if(!isAuthenticated) return;
        axios.get('/api/art-collections').then(res => setArtCollectionsList(res.data));
    }, [isAuthenticated, build]);
    
    return artCollectionsList;
}

export function getArtCollection(id) {
    return axios.get(`/api/art-collections/${id}`).then(res => (res.data));
}