import "../styles/Leaderboard.css";
import React from 'react';
import { LeaderboardContextProvider, useLeaderboard } from '../providers/LeaderboardProvider';

function LeadOreBoard() {
  return (
    <LeaderboardContextProvider>
        <_Leaderboard />
    </LeaderboardContextProvider>
  )
}

export default LeadOreBoard

function _Leaderboard() {
    const { leaderboard } = useLeaderboard();
    return(
        <section className='my-12'>
            <div className='container mx-auto'>
                <Table leaderboard={leaderboard} />            
            </div>
        </section>
    )
}

function Table({leaderboard}){

    function formatAddress(address) {
        const isMobile = window.innerWidth < 768;
        if (isMobile) {
            return address.slice(0, 6) + '...' + address.slice(-4);
        } else {
            return address;
        }
    }

    return (
        <div className="overflow-x-auto">
            <table className="leaderboard table table-zebra w-full">
                {/* head */}
                <thead>
                <tr>
                    <th></th>
                    <th>Address</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {/* rows */}
                {
                    leaderboard && leaderboard.map((row, i) => (
                        <tr key={i}>
                            <th>{i+1}</th>
                            <td>{formatAddress(row.address)}</td>
                            <td>{row.balance} <span className="font-bold">$ORE</span></td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}