import React, { useEffect } from 'react';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import StakeUnstakeButton from '../components/StakeUnstakeButton';
import TokenMenu from '../components/TokenMenu';
import AliensPredictions from '../components/predictions/AliensPredictions';
import useIsMinted from '../hooks/useIsMinted';
import { IoPlanetSharp } from 'react-icons/io5';
import PlanetsMinting from '../components/PlanetsMinting';
import GenerateArt from '../components/art/GenerateArt';
import useArtCollection from '../hooks/useArtCollection';
import ListPlaceholder from '../components/ListPlaceholder';
import { useReloadMetadata } from '../hooks/useReloadMeta';
const COLLECTION_NAME = 'Axe';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

function Aliens() {
    const { balance } = useAlienBalance();
    const collection = useArtCollection(COLLECTION_NAME);
    
  return (
    <div>
      <AliensPredictions/>
      <div className='grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-4'>
        {
          balance.map((nft, index) => {
            return (
              <Alien collection={collection} nft={nft} index={index} key={index}/>
            )
          })
        }
      </div>
      {
          balance.length === 0 && (
            <ListPlaceholder 
              title="No Aliens Found"
            />
          )
        }
    </div>
  )
}

function Alien({ nft, index, collection }) {
  const isMinted = useIsMinted(nft.token_id);
  const reloadMeta = useReloadMetadata("Aliens", nft.token_id);

  function reloadImage() {
    reloadMeta();
    console.log("reloaded " + nft.token_id);
  }

  let meta = nft.metadata ? nft.metadata : { attributes: [] };

  if (!meta.image || meta.image.includes("temporary")) {
    reloadImage();
  }

  return (
    <div key={index} className="card bg-base-100 shadow-xl overflow-visible mt-4">
      {/* <GenerateArt
        theme={
          nft.isStaked ? collection : -1
        }
        useAvatar={true}
        token={nft}
      /> */}
      <img className='t rounded-t-md' src={nft.metadata.image} />
      <div className="card-body shadow-inner justify-between">
        <div className='w-full flex justify-between items-center'>
          <h2 className="card-title">
            #{nft.token_id}
            {
              !nft.isStaked && (
                <div className="tooltip z-50" data-tip="This dude is not mining!">
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6 text-warning" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                </div>
              )
            }
            {
              isMinted === false && (
                <div className="tooltip z-50" data-tip="This dude has a planet he hasn't discover!">
                  <IoPlanetSharp className='h-6 w-6 text-warning'/>
                </div>
              )
            }
          </h2>
          <TokenMenu
            collectionName="Aliens"
            tokenId={nft.token_id}
            isMinted={isMinted}
          />
        </div>
        <div><strong>Holds:</strong> <span>{nft.holds.toFixed(3)} ORE</span></div>
        <div className='flex flex-wrap mb-3'>
          {
            Boolean(meta) && meta.attributes.map((attr, i) => (
              attr.value ? <div key={`${index}-${i}`} className="badge badge-outline m-[1px] mb-[2px] text-xs">{capitalizeFirstLetter(attr.value.toLowerCase().replaceAll('_', ' '))}</div> : ""
            ))
          }
        </div>
        <div className="card-actions justify-start">
          <StakeUnstakeButton
            collectionName="Aliens"
            tokenId={nft.token_id}
            isStaked={nft.isStaked}
          />
        </div>
      </div>
      {isMinted === false && <PlanetsMinting tokenId={nft.token_id}/>}
    </div>
  )
}

export default Aliens