import React from 'react'
import { RiAliensLine } from 'react-icons/ri';
import { useEvents } from '../providers/EventsProvider';
import date from 'date-and-time';
import { IoReloadOutline } from 'react-icons/io5';
import { IoPlanetOutline } from 'react-icons/io5';

function IncomingTransactions() {
    const { events, updateEvents } = useEvents();
    
    React.useEffect(() => {
        updateEvents();
    }, []);

  return (
    <div className="overflow-x-auto shadow-md">
        {/* <div className='mb-4 flex items-center'>
            <div className="tooltip" data-tip="Reload Events">
                <button onClick={updateEvents} className='ml-3 btn btn-ghost'><IoReloadOutline className='text-xl text-success'/></button>
            </div>
        </div> */}
        <table className="table table-compact w-full font-semibold">
            <thead>
            <tr>
                <th></th> 
                <th>Type</th> 
                <th>Amount</th>
                <th></th>
                <th></th>
                <th>Date</th> 
            </tr>
            </thead> 
            <tbody>
                {
                    events.map((event, index) => {
                        return <Event key={index} index={index} event={event} />
                    })
                }
            </tbody>
            <tfoot>
            <tr>
                <th></th> 
                <th>Type</th> 
                <th>Amount</th> 
                <th></th>
                <th></th>
                <th>Date</th> 
            </tr>
            </tfoot>
        </table>
    </div>
  )
}

function Event({ event, index }) {
    switch(event.type) {
        case 'single_transaction':
            return <SingleTransaction event={event} index={index} />;
        case 'multiple_transaction':
            return <MultipleTransaction event={event} index={index} />;
        default:
            return null;
    }
}

function SingleTransaction({ event, index }) {
    return (
        <tr>
            <th>{index+1}</th>
            <td>Single Transaction</td>
            <td>$ORE<div className="badge badge-success ml-2"><span className='font-semibold'>{event.data.amount}</span></div></td> 
            <td><div className='flex items-center'>{event.data.token.collection_name === "Aliens" ? <RiAliensLine className='w-5 h-5'/> : <IoReloadOutline className='w-5 h-5'/>}<span className='ml-2'>#{event.data.token.token_id}</span></div></td>
            <td>
                
            </td>
            <td>{date.format(new Date(event.createdAt), 'DD/MM/YYYY HH:mm')}</td>
        </tr>
    )
}

function MultipleTransaction({ event, index }) {
    return (
        <tr>
            <th>{index+1}</th>
            <td>Multiple Transaction</td>
            <td>$ORE<div className="badge badge-success ml-2"><span className='font-semibold'>{event.data.amount}</span></div></td> 
            <td><div className='flex items-center'><RiAliensLine className='w-5 h-5 mr-2'/>{event.data.aliens}</div></td>
            <td><div className='flex items-center'><IoPlanetOutline className='w-5 h-5 mr-2'/>{event.data.planets}</div></td>
            <td>{date.format(new Date(event.createdAt), 'DD/MM/YYYY HH:mm')}</td>
        </tr>
    )
}

export default IncomingTransactions;