import React from 'react';
import { useContractWrite, usePrepareContractWrite } from 'wagmi';
import { useCollectionMeta } from '../providers/CollectionMetaProvider';
import config from '../config';

function PlanetsMinting({ tokenId }) {
    const { meta: { Planets } } = useCollectionMeta();
    const params = usePrepareContractWrite({
        address: Planets.address,
        abi: config.Planets.ABI,
        functionName: 'mintPlanet',
        args: [tokenId]
    });
    const { data, isLoading, isSuccess, write } = useContractWrite(params.config);

    function getButtonMessage() {
        if (isLoading) return <progress className="progress w-56"></progress>;
        if (isSuccess) return "Minted!";
        return `Mint Planet ${tokenId}`;
    }

    return (
        <div>
            <input type="checkbox" id={`planets-minting-${tokenId}`} className="modal-toggle" />
            <label htmlFor={`planets-minting-${tokenId}`} className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="planets-minting">
                    <h3 className="text-lg font-bold">Planets Minting</h3>
                    <p className="py-4">Mint Planets for your LASC#{tokenId}. (You pay only gas).</p>
                    <div className='h-12 flex text-primary justify-center'>
                        {
                            isSuccess && (
                                <p className='mt-3 text-xl uppercase'>Minted!</p>
                            )
                        }
                    </div>
                    <button disabled={!write || isLoading || isSuccess} onClick={() => write?.()} className={`btn btn-block mt-3 ${isSuccess && "btn-success"}`}>{getButtonMessage()}</button>
                </label>
            </label>
        </div>
  )
}

export default PlanetsMinting;