import React, { useState } from 'react';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import { useAuth } from '../providers/AuthProvider';
import { useStaked } from '../providers/StakedOreProvider';
import { stakeAll, unstakeAll, collectOre } from '../util/staking';
import { useMinedOre } from '../providers/MinedOreProvider';
import { GiStopSign } from 'react-icons/gi';
import { GiSpaceShuttle } from 'react-icons/gi';
import { GiStakeHammer } from 'react-icons/gi';
import { useNotification } from '../providers/NotificationProvider';

export default function useMiningControls() {
    const [loading, setIsLoading ] = useState(false);
    const { reloadBalance: reloadAlienBalance } = useAlienBalance();
    const { reloadBalance: reloadPlanetBalance } = usePlanetBalance();
    const { updateProfile } = useAuth();
    const { updateStaked } = useStaked();
    const { updateMinedOre } = useMinedOre();
    const { notify } = useNotification();

    const unstakeTokens = () => {
        setIsLoading(true);
        unstakeAll()
            .then((e) => {
                reloadAlienBalance();
                reloadPlanetBalance();
                updateStaked();
                updateMinedOre();
                updateProfile();
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                notify(e.message);
            });
    }
  
    const stakeTokens = () => {
        setIsLoading(true);
        stakeAll()
            .then(() => {
                reloadAlienBalance();
                reloadPlanetBalance();
                updateStaked();
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                if (e.response.status === 425) {
                    const message = e.response.data.error.message;
                    notify("error", message);
                } else {
                    notify("error", "Make sure you have tokens and nothing is listed on any marketplace.");
                }
            });
    }
  
    const iCollectOre = () => {
        setIsLoading(true);
        collectOre()
            .then(() => {
                reloadAlienBalance();
                reloadPlanetBalance();
                updateStaked();
                updateMinedOre();
                updateProfile();
                setIsLoading(false);
            })
            .catch((e) => {
                setIsLoading(false);
                notify("error", e.response.data.error.message)
            });
    }

    return {
        StopMining: () => (
            <button disabled={loading} onClick={unstakeTokens} className="btn btn-sm btn-error flex items-center">
                <GiStopSign className='mr-2'/>
                <span>Stop Mining</span>
            </button>
        ),
        StartMining: () => (
            <button disabled={loading} onClick={stakeTokens} className="btn btn-sm mr-1 mb-3 xl:mb-0">
                <GiSpaceShuttle className='mr-2'/>
                <span>Start Mining</span>
            </button> 
        ),
        RefineOre: () => (
            <button disabled={loading} onClick={iCollectOre} className="btn btn-primary btn-sm flex items-center">
                <GiStakeHammer className='mr-2'/>
                <span>Refine Ore</span>
            </button>
        )
    }
}