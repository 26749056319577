import { useState, useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';
import axios from 'axios';

export function useReloadMetadata(collectionName, tokenId) {
    
    const reloadMeta = () => {
        return axios.post(`/api/token/${collectionName}/${tokenId}/request-metadata`);
    }
    
    return reloadMeta;
}