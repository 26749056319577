import React, {useRef, useEffect} from 'react'
import useLocalStorage from '../hooks/useLocalStorage'

function LicenseAgreement() {
    const [agreed, setAgreed] = useLocalStorage('agreed', false);
    const openBoxRef = useRef();

    useEffect(() => {
        if (!agreed) {
            openBoxRef.current.click();
        }
    }, [agreed]);

  return (
        <div>
            <a ref={openBoxRef} href="#my-modal-2" className="hidden"></a>
            <div className="modal" id="my-modal-2">
                <div className="modal-box">
                    <h3 className="font-bold text-lg text-primary">User Terms Agreement</h3>
                    <p className="py-4">To use this app, please agree to <a target="_black" className='text-primary underline' href="https://lonelyaliens.com/license/">Terms and Conditions</a></p>
                    <div className="form-control max-w-xs">
                        <label className="flex items-center cursor-pointer">
                            <input onChange={() => setAgreed(!agreed)} type="checkbox" checked={agreed} className="checkbox mr-3" />
                            <span className="label-text">I agree to <a target="_black" className='text-primary underline' href="https://lonelyaliens.com/license/">Terms and Conditions</a></span> 
                        </label>
                    </div>
                    <div className="modal-action">
                    <a href="#" disabled={!agreed} className="btn">Let's GO!</a>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default LicenseAgreement