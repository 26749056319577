import React, { useEffect, useRef } from 'react';
import { useAlienBalance } from '../../providers/AlienBalanceProvider';
import ipfs from '../../util/ipfs';
import { BiErrorAlt } from 'react-icons/bi';

function SelectToken({ selectToken }) {
    const { balance } = useAlienBalance();
    const closeBtn = useRef(null);

    useEffect(() => {
        if (!balance.length) return;
        selectToken(balance[0]);
    }, [balance]);

    function selectAndClose(token) {
        selectToken(token);
        closeBtn.current.click();
    }
    
    return (
        <div>
            <label htmlFor="select-token" className="btn btn-primary whitespace-nowrap btn-sm">Select Alien</label>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id="select-token" className="modal-toggle" />
            <div className="modal">
            <div className="modal-box relative">
                <label htmlFor="select-token" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                <h3 className="text-lg font-bold">Select your Alien</h3>
                <div className='grid grid-cols-2 gap-6'>

                    {
                        balance.map((token, index) => {
                            let meta;
                            try {
                                meta = token.metadata
                            } catch {
                                meta = null;
                            }
                            return (
                                <a href="#" onClick={() => selectAndClose(token) } key={index} className="card bg-base-100 shadow-xl">
                                    <figure>{meta && meta.image ? <img src={ipfs(meta.image)} alt={meta.name}/> : <BiErrorAlt className='h-48'/>}</figure>
                                    <div className="card-body p-2">
                                        <h2 className=" text-primary font-semibold text-center">{meta ? meta.name : <BiErrorAlt/>}</h2>
                                        <div className="card-actions justify-start">
                                        <label htmlFor="select-token" onClick={() => selectToken(token)} className="btn hidden sm:flex">Select</label>
                                        </div>
                                    </div>
                                </a>
                            )
                        })
                    }

                </div>
            </div>
            <label ref={closeBtn} htmlFor="select-token" className="hidden"></label>
            </div>

        </div>
  )
}

export default SelectToken;