import React from 'react';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import { useAlienBalance } from '../providers/AlienBalanceProvider';

export default function useColonies(onlyStaked=false) {
    let { balance: planetBalance } = usePlanetBalance();
    let { balance: alienBalance } = useAlienBalance();

    if (onlyStaked) {
        planetBalance = planetBalance.filter(planet => planet.isStaked);
        alienBalance = alienBalance.filter(alien => alien.isStaked);
    }

    try {
        const ids = alienBalance.filter((alien) => {
            if (planetBalance.find((planet) => planet.token_id === alien.token_id)) {
                return true;
            } else return false;
        }).map((ea) => ea.token_id);

        const aliens = ids.map(id => alienBalance.find(alien => alien.token_id === id));
        const planets = ids.map(id => planetBalance.find(planet => planet.token_id === id));

        return { planets, aliens, ids };
    } catch (e) {
        return {
            planets: [],
            aliens: [],
            ids: [],
        }
    }
}