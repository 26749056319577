import React from 'react'

function ListPlaceholder({ title }) {
  return (
    <div
        className="h-[40vh] w-full flex flex-col justify-center items-center align-center text-center text-neutral text-xl"
    >
        <p className='w-[100%]'>{title}...</p>
    </div>
  )
}

export default ListPlaceholder;