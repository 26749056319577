import React from 'react';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import isSun from '../util/isSun';

export default function usePlanets() {
    const { balance } = usePlanetBalance();
    try {
        const planets = balance.filter(planet => !isSun(planet));
        return planets;
    } catch (e) {
        return [];
    }
}