export default function ipfs(url) {
    if (!url) return url;
    const prefix = "/ipfs";

    if (url.startsWith('ipfs://')) {
        return `${prefix}/${url.replace('ipfs://', '')}`;
    }

    else if (url.startsWith('http://arweave.net')) {
        return url.replace('http://arweave.net', 'https://arweave.net');
    }

    else {
        return url;
    }
}