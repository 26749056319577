import React, { useEffect, useState } from 'react'
import Container from '../components/Container'
import H3 from '../components/H3'
import H2 from '../components/H2'
import SelectToken from '../components/art/SelectToken'
import SelectTheme from '../components/art/SelectTheme'
import GenerateArt from '../components/art/GenerateArt'
import Export from '../components/art/Export'
import { useAlienBalance } from '../providers/AlienBalanceProvider';

function Art() {
  const [token, setToken] = useState(null);
  const [theme, setTheme] = useState(null);
  const [art, setArt] = useState(null);
  const { balance } = useAlienBalance();

  const selectToken = (token) => {
    setToken(token);
  }

  const selectTheme = (theme) => {
    setTheme(theme);
  }

  const consumeArt = (art) => {
    console.log("I was called")
    console.log(art)
    setArt(art);
  }

  const afterExport = () => {}

  useEffect(() => {
    console.log(token)
  }, [token])

  useEffect(() => {
      if (!balance.length) return;
      selectToken(balance[0]);
  }, [balance]);

  if (!token) return (
    <Container bg={false}>
      <H2>Art Generator</H2>

      <div className='text-left mr-12 mt-6'>
        You must have at least one alien to generate art.
      </div>

    </Container>
  )

  return (
    <Container bg={false}>
      <H2>Art Generator</H2>

      <div className='text-left mr-12 mt-6'>
        <H3>Select Theme</H3>
        <SelectTheme
          selectTheme={selectTheme}
        />
      </div>

      <H3 className="mt-12">Options</H3>
      <div className='flex justify-start'>

        <div className='text-left mr-2 lg:mr-12'>
          <SelectToken
            selectToken={selectToken}
          />
        </div>

        <div className='text-left mr-12'>
          <Export
            theme={theme}
            art={art}
            afterExport={afterExport}
            tokenId={token ? token.token_id : -1}
          />
        </div>

      </div>

      <div className='text-left mt-12'>
        <H3>Generate Art</H3>
        <div className='max-w-[600px]'>
          <GenerateArt
            token={token}
            theme={theme}
            consumeArt={consumeArt}
          />
        </div>
      </div>
    </Container>
  )
}

export default Art