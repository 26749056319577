import React from 'react';
import ProfitabilityCalculator from '../components/ProfitabilityCalculator';
import Overall from '../components/Overall';
import MiningPanel from '../components/MiningPanel';
import CollectionPrediction from '../components/predictions/CollectionPredictions';

function Balance() {
  return (
    <div>
        <CollectionPrediction/>
        <MiningPanel/>
        <Overall/>
        <ProfitabilityCalculator/>
    </div>
  )
}

export default Balance;