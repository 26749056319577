import React, { useEffect, useState } from 'react';
import { useAuth } from '../providers/AuthProvider';
import useLocalStorage from '../hooks/useLocalStorage';
import axios from 'axios';

const MinedOreContext = React.createContext();

export function useMinedOre() {
    return React.useContext(MinedOreContext);
}

export function MinedOreProvider({ children }) {
    const [ore, setOre] = useLocalStorage('ore', null);
    const [totalOre, setTotalOre] = useLocalStorage('totalOre', null);
    const { isAuthenticated } = useAuth();

    const updateMinedOre = async () => {
        if (!isAuthenticated) return;
        try {
            const { data } = await axios.get('/api/ore');
            const ore = data.data.attributes;
            setOre(ore.supply);
            setTotalOre(ore.max_supply);
        } catch {
            // window.location.href = "/login";
        }
    }

    useEffect(() => {
        updateMinedOre();
    }, [isAuthenticated]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateMinedOre();
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <MinedOreContext.Provider value={{ ore, totalOre, updateMinedOre }}>
            {children}
        </MinedOreContext.Provider>
    );
}