import React, { useEffect, useState } from 'react';
import { useListings } from "../providers/ListingsProvider";
import Listings from './Listings';
import H2 from '../components/H2';
import Container from './Container';

function ListingsWrapper() {
    const { alienListings, planetListings } = useListings();
    const [ tabActive, setTabActive ] = useState(0);

  return (
    <div>
        <div className="tabs mb-6">
            <a onClick={() => (setTabActive(0))} className={`tab tab-lg tab-lifted ${tabActive === 0 ? "tab-active" : ""}`}>Large</a> 
            <a onClick={() => (setTabActive(1))} className={`tab tab-lg tab-lifted ${tabActive === 1 ? "tab-active" : ""}`}>Large</a> 
        </div>
        <div className='mb-6'>
            {tabActive === 0 && (<AlienListings listings={alienListings} />)}
            {tabActive === 1 && (<PlanetListings listings={planetListings} />)}
        </div>
    </div>
  )
}

function AlienListings({ listings }) {
    return (
        <div className=''>
            <H2 className="mb-3">Aliens Listings</H2>
            <Listings listings={listings} />
        </div>
    
    )
}

function PlanetListings({ listings }) {
    return (
        <div className=''>
            <H2 className="mb-3">Planet Listings</H2>
            <Listings listings={listings} />
        </div>
    )
}

export default ListingsWrapper;