import axios from 'axios';
import { useState, useEffect } from 'react';
import { useAuth } from '../providers/AuthProvider';

export default function useArtCollection(name) {
    const [artCollection, setArtCollection] = useState(undefined);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!name) return;
        if (!isAuthenticated) return;
        axios.get(`/api/art-collections/find/${name}`)
            .then(res => {
                setArtCollection(res.data);
            }
        )
            .catch(e => {
                console.log(e);
        });
    }, [name, isAuthenticated]);

  return artCollection;
}