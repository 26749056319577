import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../providers/AuthProvider';

const StakedOreContext = React.createContext();

export function useStaked() {
    return React.useContext(StakedOreContext);
}

export function StakedOreProvider({ children }) {
    const [staked, setStaked] = useState(null);
    const { isAuthenticated } = useAuth();

    const updateStaked = async () => {
        if (!isAuthenticated) return;
        try {
            const aliens = axios.get('/api/tokens?filters[collection_name][$eq]=Aliens&filters[isStaked]=true');
            const planets = axios.get('/api/tokens?filters[collection_name][$eq]=Planets&filters[isStaked]=true');
            const [aliensRes, planetsRes] = await Promise.all([aliens, planets]);
            const staked = [aliensRes.data.meta.pagination.total, planetsRes.data.meta.pagination.total];
            setStaked(staked);
        } catch {
            // window.location.href = "/login";
        }
    }

    useEffect(() => {
        updateStaked();
    }, [isAuthenticated]);

    return (
        <StakedOreContext.Provider value={{ staked, updateStaked }}>
            {children}
        </StakedOreContext.Provider>
    )
}