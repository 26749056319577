import React from 'react';
import H3 from './H3';
import PlanetsPredictinos from './predictions/PlanetsPredictinos';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import useColonies from '../hooks/useColonies';
import useSuns from '../hooks/useSuns';
import usePlanets from '../hooks/usePlanets';
import SunsPredictions from './predictions/SunsPredictions';
import ColoniesPredictions from './predictions/ColoniesPredictions';
import AliensPredictions from './predictions/AliensPredictions';

function Overall() {
  const { balance: aliens } = useAlienBalance();
  const planets = usePlanets();
  const suns = useSuns();
  const colonies = useColonies();
  return (
    <div className='rules bg-semitransparent p-6 py-3 rounded-xl'>
      <div className='mb-6 rule grid grid-cols-1 lg:grid-cols-2 gap-4 content-center lg:mb-3'>
        <PlanetsPredictinos/>
        <PlanetsRules/>
      </div>
      <div className='mb-6 rule grid grid-cols-1 lg:grid-cols-2 gap-4 content-center lg:mb-3'>
        <SunsPredictions/>
        <SunsRules/>
      </div>
      <div className='mb-6 rule grid grid-cols-1 lg:grid-cols-2 gap-4 content-center lg:mb-3'>
        <ColoniesPredictions/>
        <ColoniesRules/>
      </div>
      <div className='mb-6 rule grid grid-cols-1 lg:grid-cols-2 gap-4 content-center lg:mb-3'>
        <AliensPredictions/>
        <AliensRules/>
      </div>
    </div>
  )
}

export default Overall;

function AliensRules(rewardMultiplier) {
  if(!rewardMultiplier) return <></>;
  return (
    <div className="pl-6 mb-3">
      <H3>Aliens Yield</H3>
      <ul className='list-disc pl-3 self-center'>
        <li>Each time a new block is mined on the <strong>Ethereum</strong> network, your <strong>Aliens</strong> mine new Ore</li>
        <li><strong>Aliens</strong> without a matching <strong>Planet</strong> yield the lowest amount of <strong>Ore</strong>.</li>
        {/* <li>Each <strong>Alien</strong> can mine <strong>{rewardMultiplier.per_block_multiplier} ORE</strong> per block mined</li> */}
      </ul>
    </div>
  )
}

function PlanetsRules(rewardMultiplier) {
  if(!rewardMultiplier) return <></>;
  return (
    <div className="pl-6 mb-3">
      <H3>Planets Yield</H3>
      <ul className='list-disc pl-3 self-center'>
        {/* <li>Each time a new block is mined on the <strong>Ethereum</strong> network, your <strong>Aliens</strong> mine new Ore</li> */}
        <li>If you give <strong>Aliens</strong> permission to mine <strong>ORE</strong> on your <strong>Planet</strong>, the <strong>Planet</strong> then yields <strong>Ore</strong> as a Tax reward.</li>
        {/* <li>Each <strong>Planet</strong> earns <strong>{rewardMultiplier.per_block_multiplier} ORE</strong> in Taxes every next block</li> */}
        {/* <li>Just open your <strong>Planet's</strong> borders to get some extra <strong>ORE</strong></li> */}
      </ul>
    </div>
  )
}

function SunsRules(rewardMultiplier) {
  if (!rewardMultiplier) return <></>;
  return (
    <div className="pl-6 mb-3">
      <H3>Suns Yield</H3>
      <ul className='list-disc pl-3 self-center'>
        <li><strong>Suns</strong> are similar to <strong>Planets</strong></li>
        <li><strong>Suns</strong> have a higher concentration of <strong>Ore</strong></li>
        {/* <li><strong>Suns</strong> have <strong>{1 + rewardMultiplier.sun_multiplier} times more <strong>ORE</strong> that regular <strong>Planets</strong></strong></li> */}
      </ul>
    </div>
  )
}

function ColoniesRules(rewardMultiplier) {
  if (!rewardMultiplier) return <></>;
  return (
    <div className="pl-6 mb-3">
      <H3>Colonies Yield</H3>
      <ul className='list-disc pl-3'>
        <li><strong>A Colony</strong> is a planet or sun with a matching <strong>Alien ID</strong>.</li>
        <li><strong>Colonies</strong> yield the most <strong>Ore</strong>.</li>
        {/* <li><strong>Colonies</strong> have <strong>{1 + rewardMultiplier.colony_multiplier} times more <strong>ORE</strong> that regular <strong>Planets</strong></strong></li> */}
      </ul>
    </div>
  )
}