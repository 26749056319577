import React, { useEffect } from 'react';
import H2 from './H2';
import useMiningControls from '../hooks/useMiningControls';
import { GiCellarBarrels, GiStoneSphere } from 'react-icons/gi';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { useMinedOre } from '../providers/MyMinedOreProvider';
import CheckPlanetExistance from './CheckPlanetExistance';

function MiningPanel() {
    const { StartMining, StopMining, RefineOre } = useMiningControls();
    const { totalOre: oreMined } = useMinedOre();

    return (
    <div className='grid grid-cols-1 sm:grid-cols-2 gap-5 mt-12 mb-6 bg-semitransparent py-3 px-3 rounded-xl'>
        <div className='flex flex-col justify-between'>
            <div className=''>
                <H2 className="card-title mb-6">
                    Mining Panel
                </H2>
                <div className='flex flex-col sm:flex-row items-start mb-3 text-2xl'>
                    <div>
                        <span className='flex items-center h-4'>
                            <GiStoneSphere className='text-2xl mr-1'/>
                            {oreMined.toFixed(3)}
                            <strong className='ml-1'>ORE</strong>
                        </span>
                        <span className='text-sm'>Raw Ore</span>
                    </div>
                    <HiOutlineArrowNarrowRight className="text-2xl rotate-90 sm:rotate-0 my-3 sm:my-0 mx-2 sm:mt-[-4px]"/>
                    <div>
                        <span className='flex items-center text-primary h-4'>
                            <GiCellarBarrels className='text-2xl mr-1 fill-primary'/>
                            {(oreMined * 0.77).toFixed(3)}
                            <strong className='ml-1'>ORE</strong>
                        </span>
                        <span className='text-sm'>After refined</span>
                    </div>
                </div>
            </div>
            <div className="card-actions justify-between mt-6">
                <StopMining/>
                <StartMining/>
                <RefineOre/>
            </div>
        </div>
        <div className='flex flex-col justify-start items-center'>
            <H2 className="mb-6">Check Planet Existance</H2>
            <CheckPlanetExistance/>
        </div>
    </div>
  )
}

export default MiningPanel;