import React from 'react';
import { useAuth } from '../providers/AuthProvider';
import { Navigate } from 'react-router-dom';

function Logout() {

    const { logout } = useAuth();
    
    React.useEffect(() => {
        logout();
    }, []);

    return <Navigate to="/login" />
}

export default Logout