import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../providers/AuthProvider';

export default function useIsMinted(tokenId) {
    const [isMinted, setIsMinted] = useState(undefined);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!isAuthenticated) return;
        if (!tokenId) return;
        axios.get(`/api/nft-collections/is-minted/${tokenId}`)
            .then(res => {
                setIsMinted(res.data);
            }
        )
            .catch(e => {
                console.log(e);
                setIsMinted(true);
        });
    }, [tokenId]);

  return isMinted;
}