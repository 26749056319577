import React, { useEffect, useRef } from 'react';
const CACVAS_SIZE = window.screen.width * 0.8;
const placeholder = "/images/planet-logo.png";

function Canvas({ layers=[], consumeArt=()=>{}, totalLayers=layers.length, width=CACVAS_SIZE }) {
    const canvas = useRef(null);

    useEffect(() => {
        draw();
    }, [layers]);

    async function loadImage(href) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = href;
          image.crossOrigin = "Anonymous";
          image.addEventListener('load', () => resolve(image));
        });
    }

    function draw() {
        const ctx = canvas.current.getContext('2d');
        ctx.clearRect(0, 0, width, width);
        let imagePromises;
        if(layers.length == totalLayers) {
            imagePromises = layers.map((layer) => {
                if(!layer || !layer.length) return;
                return loadImage(layer);
            });
        } else {
            imagePromises = [loadImage(placeholder)];
        }

        Promise.all(imagePromises).then(async(images) => {
          for(let i=0; i<images.length; i++) {
            const image = images[i];
            if (image) await ctx.drawImage(image, 0, 0, width, width);
          }
          const dataURL = canvas.current.toDataURL("image/jpeg", { pixelRatio: 3 });
          canvas.current.toBlob((blob) => {
            consumeArt({ dataURL, blob });
          });
        });
        // requestAnimationFrame(draw);
    }

    return (
    <div>
        <canvas width={width} height={width} className='border-2 border-primary rounded-md w-[100%]' ref={canvas} ></canvas>
    </div>
    )
}

export default Canvas