import React from 'react';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import useLocalStorage from '../hooks/useLocalStorage';

const CollectionMetaContext = React.createContext();

export function useCollectionMeta() {
    return React.useContext(CollectionMetaContext);
}

export function CollectionMetaProvider({ children }) {
    const { isAuthenticated } = useAuth();
    const [meta, setMeta] = useLocalStorage('address', {
        "Aliens": {},
        "Planets": {},
    });

    const reloadMeta = async () => {
        if (!isAuthenticated) return;

        try {
            const { data } = await axios.get(`/api/nft-collections`);
            setMeta({
                "Aliens": data.data[0].attributes,
                "Planets": data.data[1].attributes,
            })
        } catch (error) {
            // window.location.href = "/login";
        }
    }

    React.useEffect(() => {
        reloadMeta();
    }, [isAuthenticated]);

    return (
        <CollectionMetaContext.Provider value={{ meta, reloadMeta }}>
            {children}
        </CollectionMetaContext.Provider>
    )
}