import React from 'react';
import Container from '../components/Container';
import { useAuth } from '../providers/AuthProvider';

function Login() {
    const { authenticate } = useAuth();
  return (
    <Container>
        <div className='flex justify-center align-middle'>
            <div className="card w-96 h-[78vh] bg-base-100 shadow-xl">
                <div className="card-body">
                    <h2 className="card-title self-center text-3xl">Login</h2>
                    <div className="form-control w-full h-full flex justify-center max-w-xs">
                        <button onClick={() => authenticate({redirect: "/"})} className='rounded-2xl border my-4'>
                            <img src="/images/metamask.png"/>
                        </button>
                        {/* <button className='rounded-2xl border my-4'>
                            <img src="/images/WalletConnect.png"/>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    </Container>
  )
}

export default Login