import React from 'react'
import { GiStoneSphere, GiStoneBlock } from 'react-icons/gi';
import { useOreMultiplier } from '../../providers/OreMultiplierProvider';
import abbreviateNumber from '../../util/abbreviateNumber';

function DailyPrediction({oreHoldings, numAssets, AssetIcon, multiplier=1, title}) {
    const { per_block_multiplier, blocks_per_day } = useOreMultiplier();
    const perDay = multiplier * per_block_multiplier * blocks_per_day;
    const perDayMultiple = perDay * numAssets;
    const windowWidth = window.innerWidth;

    function getValue(value) {
        value = value.toFixed(3);
        if (windowWidth < 768) {
            return abbreviateNumber(value, 1);
        } else return value;
    }

    return (
    <div className={`bg-semitransparent rounded-xl p-4 w-full grid-cols-2 grid sm:grid-cols-3 gap-8 mb-2`}>
        <div>
            <div className={`block md:flex items-center`}>
                <div className='w-[24px]'>
                    <GiStoneBlock className="hidden sm:block text-xl mr-2"/>
                </div>
                <span className='text-3xl font-semibold'>{perDay !== undefined ? getValue(perDay) : 0}<span className='text-sm'>/Day</span></span>
            </div>
            <span className='text-sm leading-tight'>{title ? title : "Single Asset per Day"}</span>
        </div>
        <div className='hidden sm:block'>
            <div className={`block md:flex items-center`}>
                <div className='w-[24px]'>
                    <GiStoneSphere className="hidden sm:block text-xl mr-2"/>
                </div>
                <span className='text-3xl font-semibold'>{perDayMultiple !== undefined ? getValue(perDayMultiple) : 0}<span className='text-sm'>/Day</span></span>
            </div>
            <span className='text-sm leading-tight'>All Assets Per Day</span>
        </div>
        <div className='sm:block'>
            <div className={`block md:flex items-center`}>
                <div className='w-[24px]'>
                    <AssetIcon className="hidden sm:block text-xl mr-2"/>
                </div>
                <span className='text-3xl font-semibold'>{oreHoldings !== undefined ? getValue(oreHoldings) : 0}<span className='text-sm'>ORE</span></span>
            </div>
            <span className='text-sm leading-tight'>Your Total Ore Unrefined</span>
        </div>
    </div>
  )
}

export default DailyPrediction;