import React from 'react'

function Loading() {
  return (
    <div className='w-full h-full flex items-center justify-center'>
        Loading...
    </div>
  )
}

export default Loading