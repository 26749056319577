import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
  } from "react-router-dom";
import Mining from './pages/Mining';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import OnlyLoggedIn from './util/OnlyLoggedIn';
import Return404 from './pages/404';
import Drawer from './components/Drawer';
import Art from './pages/Art';
import Listings from './pages/Listings';
import Aliens from './pages/Aliens';
import Planets from './pages/Planets';
import Suns from './pages/Suns';
import Colonies from './pages/Colonies';
import Balance from './pages/Balance';
import Wallet from './pages/Wallet';
import Observatory from './pages/Observatory';
import LeadOreBoard from './pages/LeadOreBoard';

function AppRouter() {
  return (
    <Router>
        <Navbar/>
        <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/logout" element={<Logout/>} />
            <Route element={
              <Drawer>
                <OnlyLoggedIn/>
              </Drawer>
            }>
              <Route element={<Mining/>}>
                <Route path="listings" element={<Listings/>} />
                <Route path="aliens" element={<Aliens/>} />
                <Route path="planets" element={<Planets/>} />
                <Route path="suns" element={<Suns/>} />
                <Route path="colonies" element={<Colonies/>} />
                <Route path="/" element={<Balance/>} />
                <Route path="/leaderboard" element={<LeadOreBoard/>} />
              </Route>
              <Route path="/wallet" element={<Wallet/>} />
              <Route path="/art" element={<Art/>} />
              {/* <Route path="/observatory" element={<Observatory/>} /> */}
              {/* <Route path="/listings" element={<Listings/>} /> */}
            </Route>
            <Route path="*" element={<Return404/>} />
        </Routes>
    <Footer/>
    </Router>
  )
}

export default AppRouter