import axios from 'axios';

async function stake(collectionName, tokenId) {
    // with axios
    return await axios.post(`/api/tokens/stake/${tokenId}/${collectionName}`);
}

async function unstake(collectionName, tokenId) {
    // with axios
    return await axios.post(`/api/tokens/unstake/${tokenId}/${collectionName}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token').replaceAll('"', '')}`,
        },
    });
}

async function stakeAll() {
    // with axios
    return await axios.post(`/api/tokens/stake-all`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token').replaceAll('"', '')}`,
        },
    });
}

async function unstakeAll() {
    // with axios
    return await axios.post(`/api/tokens/unstake-all`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token').replaceAll('"', '')}`,
        },
    });
}

async function collectOre() {
    // with axios
    return await axios.post(`/api/tokens/collect-ore`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token').replaceAll('"', '')}`,
        },
    });
}

export { stake, unstake, stakeAll, unstakeAll, collectOre };