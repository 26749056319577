import React from 'react';
import NumberPlate from './NumberPlate';
import { RiAliensLine } from 'react-icons/ri';
import { IoPlanetSharp } from 'react-icons/io5';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import { useAuth } from '../providers/AuthProvider';
import abbreviateNumber from '../util/abbreviateNumber';
import { useMinedOre } from '../providers/MinedOreProvider';
import { useStaked } from '../providers/StakedOreProvider';
import { GiCellarBarrels } from 'react-icons/gi';

function Dashboard() {
  return (
    <div className='dashboard grid grid-cols-2 md:grid-cols-4 mb-12 gap-2 z-50'>
        <OreMined className=""/>
        <Holdings className="bg-semitransparent lg:bg-semitransparent text-white"/>
        <OreMinedTotal className="bg-secondary text-white"/>
        <TotalMiners className="bg-secondary text-white"/>
    </div>
  )
}

export default Dashboard;

function TotalMiners({ className }) {
    const { staked } = useStaked();
    return (
        <NumberPlate
            title='Total Miners'
            description='Total miners across community'
            className={`${className}`}
        >
            <div className="stat-value flex items-center">
                {staked ? staked[0] : 0}
                <RiAliensLine className='text-sm sm:text-2xl'/>
                <span className='mx-2 text-xl'>ON</span>
                {staked ? staked[1] : 0}
                <IoPlanetSharp className='text-sm sm:text-2xl ml-1'/>
            </div>
        </NumberPlate>
    )
}

function OreMinedTotal({ className }) {
    const { ore, totalOre } = useMinedOre();
    return (
        <NumberPlate
            className={`${className}`}
            title='Ore Mined'
            description='Total ore mined by entire community'
        >
            {abbreviateNumber(Math.floor(Number(ore)))} / {abbreviateNumber(Math.floor(Number(totalOre)))}
        </NumberPlate>
    )
}

function Holdings({ className }) {
    const { balance: alienBalance, reloadBalance: reloadAlienBalance } = useAlienBalance();
    const { balance: planetBalance, reloadBalance: reloadPlanetBalance } = usePlanetBalance();
    const numAliens = alienBalance ? alienBalance.length : 0;
    const numPlanets = planetBalance ? planetBalance.length : 0;
    return (
        <NumberPlate
            title='Workers'
            description='The amount of Aliens and Planets'
            className={`${className}`}
        >
            <div className='flex items-center'>
                <div className="stat-value flex items-center">
                    <div className='flex items-center'>
                        <RiAliensLine className='text-2xl mr-1'/>
                        <span>{numAliens}</span>
                    </div>
                    <span className='mx-2'>/</span>
                    <div className='flex items-center mt-1'>
                        <IoPlanetSharp className='text-2xl mr-1'/>
                        <span>{numPlanets}</span>
                    </div>
                </div>
            </div>
        </NumberPlate>
    )
}

function OreMined({ className }) {
    const { user } = useAuth();
    return (
        <NumberPlate
            className={`bg-primary text-neutral ${className}`}
            title='Refined Ore'
            description='Your total Ore Refined'
        >
            {/* {user ? abbreviateNumber(user.balance) : 0} */}
            <div className='flex items-center'>
            {user ? user.balance : 0} <span className="text-md"><GiCellarBarrels className='text-2xl mr-1'/></span>
            </div>
        </NumberPlate>
    )
}