import React from 'react'
import H2 from '../components/H2';
import Container from '../components/Container';
import IncomingTransactions from '../components/IncomingTransactions';
import { useAuth } from '../providers/AuthProvider';
import useOreMined from '../hooks/useOreMined';
import { useMinedOre } from '../providers/MyMinedOreProvider';

function Wallet() {
    const { totalOre: oreMined } = useMinedOre();
    const { user } = useAuth();

  return (
    <Container className="flex flex-col items-center justify-center">
        <H2 className="my-6">Wallet</H2>
        <div className="grid grid-cols-2 mt-12">
            <div className="card bg-base-200 m-2 shadow-xl">
                <div className="card-body">
                    <div className="flex flex-col items-center">
                        <h2 className="card-title text-center">Current Ore Owned</h2>
                        <div className="text-4xl font-bold">{user.balance}</div>
                        <div className="text-xl">ORE</div>
                    </div>
                </div>
            </div>
            <div className="card bg-base-200 m-2 shadow-xl">
                <div className="card-body">
                    <div className="flex flex-col items-center">
                        <h2 className="card-title text-center">Current Ore Mined</h2>
                        <div className="text-4xl font-bold">{oreMined.toFixed(3)}</div>
                        <div className="text-xl">ORE</div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mt-12'>
            <IncomingTransactions/>
        </div>
    </Container>
  )
}

export default Wallet;