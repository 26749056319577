import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../providers/AuthProvider';

export default function useMetadata(tokenId, collectionName="Planets") {
    const [metadata, setMetadata] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!isAuthenticated) return;
        if (!tokenId) return;
        setLoading(true);
        axios.get(`/api/token/${collectionName}/${tokenId}/metadata`)
            .then(res => {
                setMetadata(res.data);
                setLoading(false);
            }
        )
            .catch(e => {
                console.log(e);
                setMetadata({name: "Unknnown", iamge: "", attributes: []});
                setLoading(false);
        });
    }, [tokenId, collectionName]);

  return {metadata, isLoading};
}