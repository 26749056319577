import React, { useMemo } from 'react';
import DailyPrediction from './DailyPrediction';
import { RiAliensLine } from 'react-icons/ri';
import { useAlienBalance } from '../../providers/AlienBalanceProvider';
import { useOreMultiplier } from '../../providers/OreMultiplierProvider';

function AliensPredictions() {
  const { balance: alienBalance } = useAlienBalance();
  const { alien_multiplier } = useOreMultiplier();
  const alienHoldings = useMemo(() => {
    return alienBalance.reduce((acc, alien) => (acc += alien.holds), 0);
  })
  return (
    <DailyPrediction
        oreHoldings={alienHoldings}
        numAssets={alienBalance.length}
        multiplier={alien_multiplier}
        AssetIcon={RiAliensLine}
    />
  )
}

export default AliensPredictions;