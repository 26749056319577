import React, { useEffect, useState, useMemo } from 'react';
import Sketch from 'react-p5';
import p5 from 'p5';
import GIF from 'gif.js';

function Canvas4GIF({ layers = [], consumeArt = () => { }, totalLayers = layers.length, width }) {
    const [images, setImages] = useState([]);
    const [hasGif, setHasGif] = useState(false);
    const [currentFrame, setCurrentFrame] = useState(0);
    const [localP5, setLocalP5] = useState(null);

    const CANVAS_SIZE = useMemo(() => {
        if (window.screen.width > 1200) {
            return 900;
        }
        else if (window.screen.width > 600) {
            return 600;
        }
        else {
            return window.screen.width;
        }
    }, []);

    useEffect(() => {
        const _images = [];
        const p5Instance = new p5();

        let isGif = false;

        layers.forEach(layer => {
            if (layer) {
                const img = p5Instance.loadImage(layer);
                _images.push(img);
                if (layer.includes(".gif")) {
                    isGif = true;
                }
            }
        });

        setImages(_images);
        setHasGif(isGif);

        if(localP5) {
            setTimeout(() => {
                exportArt(localP5);
            }, 1000);
        }

        return () => {
            p5Instance.remove();
        };
    }, [layers]);

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(CANVAS_SIZE, CANVAS_SIZE).parent(canvasParentRef);
        setLocalP5(p5);
        setTimeout(() => {
            exportArt(p5);
        }, 1000);
    };

    const draw = (p5) => {
        p5.background(0); 

        images.forEach((layer, index) => {
            p5.image(layer, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
            if (layer.numFrames() > 1) {
                if (p5.frameCount % 5 === 0) {
                    setCurrentFrame((currentFrame) => {
                        let nextFrame = currentFrame + 1;
                        if (nextFrame >= layer.numFrames()) {
                            nextFrame = 0;
                        }
                        return nextFrame;
                    });        
                }
                layer.setFrame(currentFrame);
            }
        });
    };

    const exportArt = (p5) => {
        consumeArt(exportToBlob(p5));
    }

    const exportToBlob = (p5) => {
        return new Promise((res, rej) => {
            if(!p5) return rej("No p5 instance");
            if (hasGif) {
                const gif = new GIF({
                    workers: 2,
                    quality: 10,
                    width: 900,
                    height: 900,
                    workerScript: '/lib/gif.worker.js'
                });
    
                // Assuming you'd want to capture a certain number of frames for the gif
                for (let i = 0; i < 36; i++) {
                    gif.addFrame(p5.canvas, { delay: 52 });  // 200ms delay between frames
                }
    
                gif.on('finished', function(blob) {
                    // const link = document.createElement('a');
                    // link.href = URL.createObjectURL(blob);
                    // link.download = 'download.gif';
                    // link.click();
                    res([blob, "gif"]);
                });
    
                gif.render();
            } else {
                p5.canvas.toBlob((blob) => {
                    res([blob, "png"]);
                });
            }
        })
    }

    const exportImage = () => {

        if (hasGif) {
            const gif = new GIF({
                workers: 2,
                quality: 10,
                width: 900,
                height: 900,
                workerScript: '/lib/gif.worker.js'
            });

            // Assuming you'd want to capture a certain number of frames for the gif
            for (let i = 0; i < 36; i++) {
                gif.addFrame(localP5.canvas, { delay: 52 });  // 200ms delay between frames
            }

            gif.on('finished', function(blob) {
                // const link = document.createElement('a');
                // link.href = URL.createObjectURL(blob);
                // link.download = 'download.gif';
                // link.click();
                window.open(URL.createObjectURL(blob));
            });

            gif.render();
        } else {
            localP5.saveCanvas("defaultCanvas1", "png");
        }
    };

    return (
        <div className='rounded-md border-2 border-primary overflow-hidden'>
            {layers?.length > 0 && <Sketch setup={setup} draw={draw} />}
            <button id="export" className='hidden' onClick={exportImage}></button>
        </div>
    )
}

export default Canvas4GIF;
