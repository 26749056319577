import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import data from '../data/tokenExamples';
import ipfs from '../util/ipfs';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { BiLogOut } from 'react-icons/bi';
import { GiCellarBarrels } from 'react-icons/gi';
import abbreviateNumber from '../util/abbreviateNumber';
import { RiAliensLine } from 'react-icons/ri';

export default function Navbar() {
    const { user, logout } = useAuth();
    const { balance } = useAlienBalance();

    const avatar = useMemo(() => {
        return balance.length && balance[0].metadata ?
            ipfs(balance[0].metadata.image) :
            ipfs(data.Aliens[0].metadata.image);
    }, [balance]);

    return (
    <div className="navbar bg-base-200 shadow-xl">
        <div className="flex-none">
            <label htmlFor="drawer" className="btn btn-square btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            </label>
        </div>
        <div className="flex-1">
            <Link to="/" className="normal-case text-xl ml-6 flex items-center">
                <img src="/logo192.png" alt="logo" className="inline-block w-16 h-16 mr-0 sm:mr-2" />
                <span className='hidden sm:block'>LASC</span>
            </Link>
        </div>
        {
            user && (
                <Link to="/wallet" className="rounded-md hidden sm:flex p-3 mx-4 text-md bg-neutral">
                    <GiCellarBarrels className='text-2xl mr-1'/>
                    <span className='font-semibold'>{user ? abbreviateNumber(Number(user.balance.toFixed(3))) : 0}</span>
                </Link>
            )
        }
        <div className="dropdown dropdown-end">
            
            {
                user ? (
                    <label tabIndex={0} className="rounded-md bg-primary flex px-2 py-1 items-center">
                        {avatar ? <img src={avatar} alt="avatar" className="w-10 h-10 rounded-full mask mask-decagon mr-2" /> : <RiAliensLine className='text-black text-2xl mr-2'/>}
                        <div className='flex flex-col justify-center items-start capitalize text-black font-semibold leading-4 text-sm p-1' >
                            <span className=' whitespace-nowrap'>Connected As</span>
                            <span className='text-xs'>{user.address.substring(0, 8)}...</span>
                        </div>
                    </label>
                ) : (
                    <></>
                )
            }
            
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                <li>
                    <Link to="/wallet">
                        <MdAccountBalanceWallet className='text-neutral text-xl'/>
                        Balance
                    </Link>
                </li>
                <li>
                    <button onClick={logout}>
                        <BiLogOut className='text-neutral text-xl'/>
                        Logout
                    </button>
                </li>
            </ul>
        </div>
    </div>
    )
}