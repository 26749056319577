export default {
  "Suns": [
      {
          "token_address": "0x2299bfdd01ea047a1a3231c2702bfcb44c1e4d25",
          "token_id": "3231",
          "transfer_index": [
              15885947,
              93,
              281,
              0
          ],
          "owner_of": "0x860ddacf2fbdb4bbf99d00117d22a1f46669fc5c",
          "block_number": "15885947",
          "block_number_minted": "13195453",
          "token_hash": "000ce2eb3223ead8746b9181df1f9de1",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "LonelyPlanetSpaceObservatory",
          "symbol": "LPSO",
          "token_uri": "https://ipfs.moralis.io:2053/ipfs/QmdX5Rw9mo3F6vq8ZdCvaFFAzD7odb8Zecc8y2Pjbbgt3J/o_0/metaout/3231",
          "metadata": {
              "name": "Lonely Planet #3231",
              "image": "https://gateway.moralisipfs.com/ipfs/QmfF4TZ1uCEELeqRXx2avux3rN1vowjdGoaKD1EPPrHyM2/o_0/GitDL/aliens_dl/PLANETOUT/3231.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "GREY"
                  },
                  {
                      "trait_type": "NEBULA",
                      "value": "BLUE_RADIATION"
                  },
                  {
                      "trait_type": "STARS",
                      "value": "PINK"
                  },
                  {
                      "trait_type": "GLOW",
                      "value": "BRIGHT_GREEN"
                  },
                  {
                      "trait_type": "GLOBE",
                      "value": "SUN"
                  }
              ]
          },
          "last_token_uri_sync": "2022-11-05T21:56:40.359Z",
          "last_metadata_sync": "2022-11-05T21:56:45.999Z",
          "minter_address": "0x3ab9b518681de505de7a1116db4b1732ceb0becd"
      },
      {
          "token_address": "0x2299bfdd01ea047a1a3231c2702bfcb44c1e4d25",
          "token_id": "7132",
          "transfer_index": [
              13321079,
              132,
              167,
              0
          ],
          "owner_of": "0x44ccfc43bfa653bd4cf0fedaf3ba789a9bc36ad0",
          "block_number": "13321079",
          "block_number_minted": "13321079",
          "token_hash": "d9d368ec036ff27691303f06992724b6",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "LonelyPlanetSpaceObservatory",
          "symbol": "LPSO",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LPSO/7132",
          "metadata": {
              "name": "Lonely Planet #7132",
              "image": "https://gateway.moralisipfs.com/ipfs/QmfF4TZ1uCEELeqRXx2avux3rN1vowjdGoaKD1EPPrHyM2/o_0/GitDL/aliens_dl/PLANETOUT/7132.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "PINK"
                  },
                  {
                      "trait_type": "NEBULA",
                      "value": "PINK_LIGHT"
                  },
                  {
                      "trait_type": "STARS",
                      "value": "PINK"
                  },
                  {
                      "trait_type": "GLOW",
                      "value": "PINK"
                  },
                  {
                      "trait_type": "GLOBE",
                      "value": "SUN"
                  },
                  {
                      "trait_type": "OUTER",
                      "value": "PURPLE_FLAMES"
                  }
              ]
          },
          "last_token_uri_sync": null,
          "last_metadata_sync": null,
          "minter_address": "0x44ccfc43bfa653bd4cf0fedaf3ba789a9bc36ad0"
      }
  ],
  "Planets": [
      {
          "token_address": "0x2299bfdd01ea047a1a3231c2702bfcb44c1e4d25",
          "token_id": "44",
          "transfer_index": [
              13355786,
              104,
              167,
              0
          ],
          "owner_of": "0x722bddc0f645c7e4906406376641e2db0ed03e3d",
          "block_number": "13355786",
          "block_number_minted": "13355786",
          "token_hash": "c3c897951705f3e54e1892853b62367f",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "LonelyPlanetSpaceObservatory",
          "symbol": "LPSO",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LPSO/44",
          "metadata": {
              "name": "Lonely Planet #44",
              "image": "https://gateway.moralisipfs.com/ipfs/QmfF4TZ1uCEELeqRXx2avux3rN1vowjdGoaKD1EPPrHyM2/o_0/GitDL/aliens_dl/PLANETOUT/44.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "GREEN"
                  },
                  {
                      "trait_type": "NEBULA",
                      "value": "PURPLE_STRIPE"
                  },
                  {
                      "trait_type": "STARS",
                      "value": "RED_STRANDS"
                  },
                  {
                      "trait_type": "GLOW",
                      "value": "YELLOW"
                  },
                  {
                      "trait_type": "GLOBE",
                      "value": "BLACK"
                  },
                  {
                      "trait_type": "SURFACE",
                      "value": "FADE"
                  },
                  {
                      "trait_type": "OUTER",
                      "value": "PINK_MOON"
                  },
                  {
                      "trait_type": "RINGS",
                      "value": "ORANGE_VERTICAL"
                  }
              ]
          },
          "last_token_uri_sync": null,
          "last_metadata_sync": null,
          "minter_address": "0x722bddc0f645c7e4906406376641e2db0ed03e3d"
      },
      {
          "token_address": "0x2299bfdd01ea047a1a3231c2702bfcb44c1e4d25",
          "token_id": "46",
          "transfer_index": [
              13317417,
              153,
              255,
              0
          ],
          "owner_of": "0xfafdf997f529163b6f988488b9e9a98ba5225d22",
          "block_number": "13317417",
          "block_number_minted": "13317417",
          "token_hash": "9f0fb0e87b11d197783ffacb8777dfea",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "LonelyPlanetSpaceObservatory",
          "symbol": "LPSO",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LPSO/46",
          "metadata": {
              "name": "Lonely Planet #46",
              "image": "https://gateway.moralisipfs.com/ipfs/QmfF4TZ1uCEELeqRXx2avux3rN1vowjdGoaKD1EPPrHyM2/o_0/GitDL/aliens_dl/PLANETOUT/46.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "BLUE"
                  },
                  {
                      "trait_type": "NEBULA",
                      "value": "BLUE_STRIPE"
                  },
                  {
                      "trait_type": "STARS",
                      "value": "CHROMA"
                  },
                  {
                      "trait_type": "GLOW",
                      "value": "GREEN"
                  },
                  {
                      "trait_type": "GLOBE",
                      "value": "TEAL"
                  },
                  {
                      "trait_type": "SURFACE",
                      "value": "PURPLE_WAVES"
                  },
                  {
                      "trait_type": "OUTER",
                      "value": "PINK_METEORS"
                  },
                  {
                      "trait_type": "RINGS",
                      "value": "PINK_ELECTRIC"
                  }
              ]
          },
          "last_token_uri_sync": null,
          "last_metadata_sync": null,
          "minter_address": "0xfafdf997f529163b6f988488b9e9a98ba5225d22"
      },
      {
          "token_address": "0x2299bfdd01ea047a1a3231c2702bfcb44c1e4d25",
          "token_id": "48",
          "transfer_index": [
              13202714,
              260,
              312,
              0
          ],
          "owner_of": "0x1bcd84b45ca19d8ba15de4a3f7d316f9f44b7194",
          "block_number": "13202714",
          "block_number_minted": "13202714",
          "token_hash": "7dd4ecd0fbebb0605ffc9730c58b1ceb",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "LonelyPlanetSpaceObservatory",
          "symbol": "LPSO",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LPSO/48",
          "metadata": {
              "name": "Lonely Planet #48",
              "image": "https://gateway.moralisipfs.com/ipfs/QmfF4TZ1uCEELeqRXx2avux3rN1vowjdGoaKD1EPPrHyM2/o_0/GitDL/aliens_dl/PLANETOUT/48.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "GREEN"
                  },
                  {
                      "trait_type": "NEBULA",
                      "value": "PINK_WHISP"
                  },
                  {
                      "trait_type": "STARS",
                      "value": "ORANGE"
                  },
                  {
                      "trait_type": "GLOW",
                      "value": "PURPLE"
                  },
                  {
                      "trait_type": "GLOBE",
                      "value": "AQUA"
                  },
                  {
                      "trait_type": "SURFACE",
                      "value": "PINK_RUBBLE"
                  },
                  {
                      "trait_type": "RINGS",
                      "value": "PURPLE_VERTICAL"
                  }
              ]
          },
          "last_token_uri_sync": null,
          "last_metadata_sync": null,
          "minter_address": "0x1bcd84b45ca19d8ba15de4a3f7d316f9f44b7194"
      }
  ],
  "Aliens": [
      {
          "token_address": "0x343f999eaacdfa1f201fb8e43ebb35c99d9ae0c1",
          "token_id": "44",
          "transfer_index": [
              13329860,
              34,
              70,
              0
          ],
          "owner_of": "0x722bddc0f645c7e4906406376641e2db0ed03e3d",
          "block_number": "13329860",
          "block_number_minted": "12837492",
          "token_hash": "fe05304ea74d76a669db747a398b5229",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "Loneley Aliens Space Club",
          "symbol": "LASC",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LASC/44",
          "metadata": {
              "name": "Lonely Alien #44",
              "image": "https://gateway.moralisipfs.com/ipfs/QmUjA4yKF9CKteD8hdH2VtEu5bJoQijixDxaq7fBpp2WXp/o_0/output/44.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "BLUE"
                  },
                  {
                      "trait_type": "BODY",
                      "value": "PURPLE"
                  },
                  {
                      "trait_type": "MOUTH",
                      "value": "SMILE"
                  },
                  {
                      "trait_type": "SHIRT",
                      "value": "TANKTOP"
                  },
                  {
                      "trait_type": "GLASSES",
                      "value": "3D_GLASSES"
                  },
                  {
                      "trait_type": "HAT",
                      "value": "BEER_HAT"
                  },
                  {
                      "trait_type": "FACE",
                      "value": "TENTACLE_BEARD_TEAL"
                  },
                  {
                      "trait_type": "OS",
                      "value": "WINGS_GREEN"
                  },
                  {
                      "trait_type": "ACCESSORY",
                      "value": "JOINT"
                  }
              ]
          },
          "last_token_uri_sync": "2022-05-13T01:38:08.642Z",
          "last_metadata_sync": "2022-05-13T19:58:14.909Z",
          "minter_address": "0x93a3cf8aaf3f6e4c2239245c4fd60f2d1f4fecbc"
      },
      {
          "token_address": "0x343f999eaacdfa1f201fb8e43ebb35c99d9ae0c1",
          "token_id": "46",
          "transfer_index": [
              12857971,
              55,
              66,
              0
          ],
          "owner_of": "0xfafdf997f529163b6f988488b9e9a98ba5225d22",
          "block_number": "12857971",
          "block_number_minted": "12837492",
          "token_hash": "921fb7a02f202d9e868545f3c37d26cf",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "Loneley Aliens Space Club",
          "symbol": "LASC",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LASC/46",
          "metadata": {
              "name": "Lonely Alien #46",
              "image": "https://gateway.moralisipfs.com/ipfs/QmUjA4yKF9CKteD8hdH2VtEu5bJoQijixDxaq7fBpp2WXp/o_0/output/46.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "TEAL"
                  },
                  {
                      "trait_type": "BODY",
                      "value": "PURPLE"
                  },
                  {
                      "trait_type": "MOUTH",
                      "value": "SMILE"
                  },
                  {
                      "trait_type": "SHIRT",
                      "value": "BLACK_TEE"
                  },
                  {
                      "trait_type": "HAIR",
                      "value": "PINK_AFRO"
                  },
                  {
                      "trait_type": "GLASSES",
                      "value": "COOL_SHADES"
                  },
                  {
                      "trait_type": "FACE",
                      "value": "STUBBLE"
                  },
                  {
                      "trait_type": "OS",
                      "value": "TURTLENECK_BLACK"
                  }
              ]
          },
          "last_token_uri_sync": "2022-05-13T01:37:44.586Z",
          "last_metadata_sync": "2022-05-19T20:10:01.352Z",
          "minter_address": "0x93a3cf8aaf3f6e4c2239245c4fd60f2d1f4fecbc"
      },
      {
          "token_address": "0x343f999eaacdfa1f201fb8e43ebb35c99d9ae0c1",
          "token_id": "48",
          "transfer_index": [
              12859649,
              143,
              176,
              0
          ],
          "owner_of": "0x1bcd84b45ca19d8ba15de4a3f7d316f9f44b7194",
          "block_number": "12859649",
          "block_number_minted": "12837492",
          "token_hash": "79d96221dca3f63838f3c1ba65353ef2",
          "amount": "1",
          "contract_type": "ERC721",
          "name": "Loneley Aliens Space Club",
          "symbol": "LASC",
          "token_uri": "https://api.lonelyaliens.com/api/meta/LASC/48",
          "metadata": {
              "name": "Lonely Alien #48",
              "image": "https://gateway.moralisipfs.com/ipfs/QmUjA4yKF9CKteD8hdH2VtEu5bJoQijixDxaq7fBpp2WXp/o_0/output/48.png",
              "attributes": [
                  {
                      "trait_type": "BACKGROUND",
                      "value": "RED"
                  },
                  {
                      "trait_type": "BODY",
                      "value": "PINK"
                  },
                  {
                      "trait_type": "TATTOO",
                      "value": "PRISON"
                  },
                  {
                      "trait_type": "MOUTH",
                      "value": "FROWN"
                  },
                  {
                      "trait_type": "EYES",
                      "value": "ANGRY"
                  },
                  {
                      "trait_type": "SHIRT",
                      "value": "LOGO_TEE"
                  },
                  {
                      "trait_type": "HAT",
                      "value": "HALO"
                  },
                  {
                      "trait_type": "OS",
                      "value": "ORANGE_WINTER_JACKET"
                  }
              ]
          },
          "last_token_uri_sync": "2022-05-13T01:58:55.750Z",
          "last_metadata_sync": "2022-05-19T11:45:02.847Z",
          "minter_address": "0x93a3cf8aaf3f6e4c2239245c4fd60f2d1f4fecbc"
      }
  ]
}