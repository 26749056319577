import React, { useState, useEffect } from 'react'
import H2 from './H2'
import H3 from './H3'
import data from "../data/tokenExamples";
import { RiAliensLine } from 'react-icons/ri';
import { IoPlanetOutline } from 'react-icons/io5';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import { GiOrbit, GiSundial } from 'react-icons/gi';
import ipfs from '../util/ipfs';
import { Link } from 'react-router-dom';
import useColonies from '../hooks/useColonies';
import usePlanets from '../hooks/usePlanets';
import useSuns from '../hooks/useSuns';

function BalanceSummary({ className }) {
    return (
        <div className={`grid grid-cols-2 sm:grid-cols-5 gap-3 mb-6`}>
            <Overall className={`col-span-2 sm:col-span-1`}/>
            <Planets/>
            <Suns/>
            <Colonies/>
            <Aliens/>
        </div>
    )
}

function Colonies() {
    const colonies = useColonies();
    return (
        <LinkCard
            to="/colonies"
            title="Colonies"
            src="/images/Colony.jpg"
        >
            <GiOrbit className="text-2xl"/>
            <span className='ml-2 text-xl'>{colonies.ids.length}</span>
        </LinkCard>
    )
}

function Aliens() {
    const { balance } = useAlienBalance();
    return (
        <LinkCard
            to="/aliens"
            title="Aliens"
            src="/images/1012.png"
        >
            <RiAliensLine className="text-2xl"/>
            <span className='ml-2 text-xl'>{balance.length}</span>
        </LinkCard>
    )
}

function Suns() {
    const suns = useSuns();
    const sunSrc = ipfs(data.Suns[0].metadata.image);
    return (
        <LinkCard
            to="/suns"
            title="Suns"
            src={sunSrc}
        >
            <GiSundial className="text-2xl"/>
            <span className='ml-2 text-xl'>{suns.length}</span>
        </LinkCard>
    )
}

function Planets() {
    const planets = usePlanets();
    return (
        <LinkCard
            to="/planets"
            title="Planets"
            src="/images/Planet.png"
        >
            <IoPlanetOutline className="text-2xl"/>
            <span className='ml-2 text-xl'>{planets.length}</span>
        </LinkCard>
    )
}

function Overall(className) {
    return (
        <LinkCard
            to="/"
            src="/images/9832.png"
            title="Overall"
            className="col-span-2 sm:col-span-1"
        >
            <RiAliensLine className="text-xl mr-1"/>
            <IoPlanetOutline className="text-xl mr-1"/>
            <GiSundial className="text-xl mr-1"/>
            <GiOrbit className="text-xl mr-1"/>
        </LinkCard>
    )
}

function LinkCard({ to, src, children, title, className }) {
    return (
        <div className={`card bg-base-100 shadow-xl image-full ${className}`} style={{
            backgroundImage: src ? `url(${src})` : "none",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }}>
            <Link className="z-10 md:h-28" to={to} >
                <div className="card-body px-3 py-2">
                    <h3 className='text-xl lg:text-2xl text-white uppercase font-semibold'>{title}</h3>
                    <div className='flex items-center text-4xl text-white'>
                        { children }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default BalanceSummary;