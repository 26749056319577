import React, { useState, useEffect } from 'react';
import { GiPlanetConquest } from 'react-icons/gi';
import useIsMinted from '../hooks/useIsMinted';
import useMetadata from '../hooks/useMetadata';
import ipfs from '../util/ipfs';
import { GiShipBow } from 'react-icons/gi';
import Loading from './Loading';

function CheckPlanetExistance() {
    const [planetId, setPlanetId] = useState("");
    const [idToCheck, setIdToCheck] = useState(undefined);
    const isMinted = useIsMinted(idToCheck);

    function checkId() {
        setIdToCheck(planetId);
    }
    

  return (
    <div className='flex flex-col items-center'>
        <input value={planetId} onChange={(e) => (setPlanetId(e.target.value))} type="number" placeholder="Enter Planet ID" className="input input-bordered w-full max-w-xs mb-8" />
        <div className='w-full flex justify-center max-w-xs'>
            <label htmlFor="check-planet-minted" onClick={checkId} className="btn btn-sm"><GiPlanetConquest className='mr-2 text-xl' />Check Planet</label>
        </div>
        <input type="checkbox" id="check-planet-minted" className="modal-toggle" />

        <div className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
                {
                    idToCheck ? (
                        <PlanetCard id={idToCheck} isMinted={isMinted} />
                    ) : (
                        <>
                            <h3 className="font-bold text-lg">Please, Enter a token ID first</h3>
                            <p className="py-4">Enter a Planet ID in the input to check if it was minted</p>
                            <div className="modal-action">
                                <label htmlFor="check-planet-minted" className="btn">Okay</label>
                            </div>
                        </>
                    )
                }
            </div>
        </div>

    </div>
  )
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function PlanetCard({id, isMinted}) {
    const {metadata, isLoading} = useMetadata(id, "Planets");
    return (
        <>
            {
                isLoading ? (
                    <div>
                        <Loading/>
                    </div>
                ) : (
                    <div className='grid grid-cols-1 md:gap-4 md:grid-cols-2'>
                <div className=''>
                    <img src={metadata && metadata.image && metadata.image.length ? ipfs(metadata.image) : "/images/black-hole.png"} className='rounded shadow-md' />
                </div>
                <div className='flex flex-col justify-between'>
                    {
                        isMinted ? 
                            <h3 className="font-bold text-lg leading-tight">{metadata.name}</h3>
                        :
                            <h3 className="font-bold text-lg leading-tight">Planet with id {id} hasn't been born yet</h3>
                    }
                    {
                        isMinted && metadata ? (
                            <div>
                                {
                                    metadata.attributes.map((attr, i) => (
                                        <div key={`${i}`} className="badge badge-outline m-[1px] mb-[2px] text-xs">{capitalizeFirstLetter(attr.value.toLowerCase().replaceAll('_', ' '))}</div>
                                      ))
                                }
                            </div>
                        ) : (
                            <div>
                                {
                                    ["????", "????", "?????", "???", "?????", "????", "??????? ??" ].map((attr, i) => (
                                        <div key={`${i}`} className="badge badge-outline m-[1px] mb-[2px] text-xs">{capitalizeFirstLetter(attr)}</div>
                                      ))
                                }
                            </div>
                        )
                    }
                    <div>
                    </div>
                    <div className="modal-action flex items-center justify-between">
                        {
                            isMinted && (
                                <a className='flex items-center btn' target="_blank" href={`https://opensea.io/assets/ethereum/0x2299bfdd01ea047a1a3231c2702bfcb44c1e4d25/${id}`}>
                                    <GiShipBow/>
                                    <span className='ml-2 whitespace-nowrap'>View</span>
                                </a>
                            )
                        }
                        <label htmlFor="check-planet-minted" className="btn">Close</label>
                    </div>
                </div>
            </div>
                )
            }
        </>
    )
}

export default CheckPlanetExistance;