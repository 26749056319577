import React, { useState, useEffect } from 'react'

function LayerOptionSelector({ layer, selectLayer, index }) {

    const select = (variant) => {
        const src = variant.image.url;
        selectLayer(index, src);
    }

    return (
    <div>
        <div className="dropdown mb-6">
            <label tabIndex={0} className="btn btn-primary btn-sm m-1">{layer.title}</label>
            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            {
                layer.variant.map((variant, i) => (
                    <li onClick={() => select(variant)} key={variant.id} tabIndex={i} className="">
                        <div className="z-50">
                            <button className='flex items-center'>
                                <img className=' w-16 mr-2' src={variant.preview.formats.thumbnail.url} />
                                <p className="text-white">{variant.trait_value}</p>
                            </button>
                        </div>
                    </li>
                ))
            }
            </ul>
        </div>
    </div>
    )
}

export default LayerOptionSelector;