import React, { useEffect, useState } from 'react';
import axios from 'axios'

const ListingsContext = React.createContext();

export function useListings() {
    return React.useContext(ListingsContext);
}

export function ListingsProvider({ children }) {
    const [alienListings, setAlienListings] = useState([]);
    const [planetListings, setPlanetListings] = useState([]);

    const loadListings = (collection) => {
        axios.get(`/api/listings/${collection}`)
            .then(res => {
                collection === "Aliens" ? setAlienListings(res.data) : setPlanetListings(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        loadListings("Aliens");
        loadListings("Planets");
    }, []);

    return (
        <ListingsContext.Provider value={{
            alienListings,
            planetListings,
            loadAlienListings: () => (loadListings("Aliens")),
            loadPlanetListings: () => (loadListings("Planets"))
        }}>
            {children}
        </ListingsContext.Provider>
    )
}