import React from 'react';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import isSun from '../util/isSun';

export default function usePlanets() {
    const { balance } = usePlanetBalance();
    try {
        const suns = balance.filter(planet => isSun(planet));
        return suns;
    } catch (e) {
        return [];
    }
}