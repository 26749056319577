import React from 'react';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import useLocalStorage from '../hooks/useLocalStorage';

const LeaderboardContext = React.createContext();

export function useLeaderboard() {
    return React.useContext(LeaderboardContext);
}

export function LeaderboardContextProvider({ children }) {
    const { isAuthenticated } = useAuth();
    const [leaderboard, setLeaderboard] = useLocalStorage('leaderboard', []);

    const reloadLeaderboard = async () => {
        if (!isAuthenticated) return;

        try {
            const { data } = await axios.get(`/api/ore/stats`);
            setLeaderboard(data);
        } catch (error) {
            // window.location.href = "/login";
        }
    }

    React.useEffect(() => {
        reloadLeaderboard();
    }, [isAuthenticated]);

    return (
        <LeaderboardContext.Provider value={{ leaderboard, reloadLeaderboard }}>
            {children}
        </LeaderboardContext.Provider>
    )
}