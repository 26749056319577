import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNotification } from '../../providers/NotificationProvider';

function Export( { theme, art, tokenId, afterExport } ) {
  const { notify } = useNotification();
  const [canExport, setCanExport] = useState(false);

  useEffect(() => {
    if (!theme) return;
    const URLs = theme.layers.map((layer) => layer.variant.map((variant) => (variant.image.url)));
    setCanExport(
      URLs.flat().find((url) => (url.includes(".gif"))) ? false : true
    );
  }, [theme]);

  function exportImage() {
    document.getElementById("export").click();
  }

  async function exportServer() {
    if (tokenId < 0) return;
    const [blob, filetype] = await (art);
    const formData = new FormData();
    formData.append(`files`, blob, `${tokenId}`);
    formData.append('filetype', filetype);
    axios.post('/api/token/setAvatar', formData)
        .then(() => (notify(1, 'Avatar set successfully!')))
        .catch(() => (notify(0, "Something went wrong here")));
  }

  return (
    <div className='flex items-center'>
      <button onClick={exportImage} className='btn btn-primary btn-sm'>Export Image</button>
      <button onClick={exportServer} disabled={tokenId < 0 || !canExport} className='btn btn-primary btn-sm ml-2'>Save as avatar</button>
    </div>
  )
}

export default Export;