import './App.css';
import axios from 'axios';
import React from 'react';
import AppRouter from './Router';
import { createClient, configureChains, defaultChains, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { AuthProvider } from './providers/AuthProvider';
import { AlienBalanceProvider } from './providers/AlienBalanceProvider';
import { MoralisProvider } from "react-moralis";
import { PlanetBalanceProvider } from './providers/PlanetBalanceProvider';
import { EventsProvider } from './providers/EventsProvider';
import { MinedOreProvider } from './providers/MinedOreProvider';
import { StakedOreProvider } from './providers/StakedOreProvider';
import { OreMultiplierProvider } from './providers/OreMultiplierProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import { CollectionMetaProvider } from './providers/CollectionMetaProvider';
import { MyMinedOreProvider } from './providers/MyMinedOreProvider';

function App() {
  axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://mining.localhost/' : 'https://mining.lonelyaliens.com/';
  axios.interceptors.response.use(
    response => response,
    error => {
      if ((error.response.status === 401 || error.response.status === 403) && window.location.pathname !== "/login") {
        return window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  const { provider, webSocketProvider } = configureChains(defaultChains, [publicProvider()]);

  const client = createClient({
    provider,
    webSocketProvider,
    autoConnect: true,
  });


  return (
    <MoralisProvider serverUrl="https://9h6njbiub6q7.usemoralis.com:2053/server" appId="SDUCZs1LiGtBgGrB3Zmqh6cUpwvTH7fQesdp3aU5">
      <WagmiConfig client={client}>
        <AuthProvider>
          <CollectionMetaProvider>
            <OreMultiplierProvider>
              <AlienBalanceProvider>
                <PlanetBalanceProvider>
                  <MinedOreProvider>
                    <MyMinedOreProvider>
                      <StakedOreProvider>
                        <EventsProvider>
                          <NotificationProvider>
                            <AppRouter/>
                          </NotificationProvider>
                        </EventsProvider>
                      </StakedOreProvider>
                    </MyMinedOreProvider>
                  </MinedOreProvider>
                </PlanetBalanceProvider>
              </AlienBalanceProvider>
            </OreMultiplierProvider>
          </CollectionMetaProvider>
        </AuthProvider>
      </WagmiConfig>
    </MoralisProvider>
  );
}

export default App;
