import React, { useEffect, useState, useMemo } from 'react';
import H3 from './H3';
import H2 from './H2';
import { useOreMultiplier } from '../providers/OreMultiplierProvider';
import { AiFillQuestionCircle } from 'react-icons/ai';

function ProfitabilityCalculator() {
    const multipliers = useOreMultiplier();
    const [suns, setSuns] = useState(0);
    const [colonies, setColonies] = useState(0);
    const [aliens, setAliens] = useState(0);
    const [planets, setPlanets] = useState(0);
    const [time, setTime] = useState(1);
    const [timeFrame, setTimeFrame] = useState('Day');
    const [orePerBlock, setOrePerBlock] = useState(0);

    const blocksPerMinute = useMemo(() => (
        multipliers.blocks_per_day / 24 / 60
    ))

    const blocksPerHour = useMemo(() => (
        multipliers.blocks_per_day / 24
    ))

    function calculateOre() {
        let ore = 0;
        ore += (suns * (multipliers.sun_multiplier + multipliers.planet_multiplier));
        ore += (colonies * multipliers.colony_multiplier);
        ore += (aliens * multipliers.alien_multiplier);
        ore += (planets * multipliers.planet_multiplier);
        ore *= time;
        
        switch(timeFrame) {
            case 'Minute':
                ore *= blocksPerMinute;
                break;
            case 'Hour':
                ore *= blocksPerHour;
                break;
            case 'Day':
                ore *= multipliers.blocks_per_day;
                break;
            case 'Block':
                ore *= 1;
                break;
            case 'Second':
                ore *= blocksPerMinute / 60;
                break;
            default:
                ore *= multipliers.blocks_per_day;
                break;
        }

        ore *= multipliers.per_block_multiplier;

        setOrePerBlock(Math.ceil(ore*1000)/1000);
    }

    function verifyColonies(num) {
        const possiblePlanets = Math.min(aliens, planets);
        const possibleSuns = Math.min(aliens, suns);
        const possibleColonies = possiblePlanets + possibleSuns;
        if (num > possibleColonies) return;
        else {
            setColonies(num);
        }
    }

    useEffect(() => {        
        calculateOre();
    }, [suns, colonies, aliens, planets, multipliers, timeFrame, time]);

  return (
    <div className='w-full mt-6 bg-semitransparent py-3 px-6 rounded-xl'>
        <div className="p-1 sm:p-3">
            <H2 className="mb-1 text-start w-full">Profitability Calculator</H2>
            <p className='text-md mb-12 uppercase'>Work x Time = ORE</p>
            
            <div className='flex flex-col items-start justify-start mb-6'>
                <H3>Assets</H3>
                <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 mt-3'>
                    <div className="form-control">
                        <label className="input-group input-group-vertical">
                            <span>Suns</span>
                            <input min={0} onChange={(e) => (setSuns(e.target.value))} type="number" value={suns} placeholder="0" className="input input-bordered" />
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="input-group input-group-vertical">
                            <span>Planets</span>
                            <input min={0} onChange={(e) => (setPlanets(e.target.value))} type="number" value={planets} placeholder="0" className="input input-bordered" />
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="input-group input-group-vertical">
                            <span>Aliens</span>
                            <input min={0} onChange={(e) => (setAliens(e.target.value))} type="number" value={aliens} placeholder="0" className="input input-bordered" />
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="input-group input-group-vertical">
                            <span>
                                <div className="tooltip" data-tip="How many Planets and Aliens form Colonies (increase assets amount first)">
                                    <AiFillQuestionCircle className='text-gray-400 hover:text-gray-500 cursor-pointer mr-2' />
                                </div>
                                Colonies
                            </span>
                            <input min={0} onChange={(e) => (verifyColonies(e.target.value))} type="number" value={colonies} placeholder="0" className="input input-bordered" />
                        </label>
                    </div>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row items-stretch mb-12'>
                <div>
                    <H3>Time</H3>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">How much time?</span>
                        </label>
                        <label className="input-group">
                            <input value={time} min={0} onChange={(e) => (setTime(e.target.value))} type="number" className="input input-bordered" />
                            <select value={timeFrame} onChange={(e) => (setTimeFrame(e.target.value))} className='select select-bordered'>
                                <option value="Day" >Days</option>
                                <option value="Hour" >Hours</option>
                                <option value="Minute" >Minutes</option>
                                <option value="Second" >Seconds</option>
                                <option value="Block" >Blocks</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div className='ml-6 flex items-stretch justify-between flex-col'>
                    <H3>Profitability</H3>
                    <p className='text-3xl flex items-end pb-3 text-neutral'>{orePerBlock} <strong className='ml-1'>ORE</strong></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProfitabilityCalculator;