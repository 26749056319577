import React, { useMemo } from 'react';
import { useMinedOre } from '../../providers/MyMinedOreProvider';
import useSuns from '../../hooks/useSuns';
import usePlanets from '../../hooks/usePlanets';
import { useAlienBalance } from '../../providers/AlienBalanceProvider';
import { useOreMultiplier } from '../../providers/OreMultiplierProvider';
import useColonies from '../../hooks/useColonies';
import { GiStoneSphere, GiStoneBlock } from 'react-icons/gi';
import { AiOutlineWallet } from 'react-icons/ai';
import abbreviateNumber from '../../util/abbreviateNumber';

function CollectionPrediction() {
    const { totalOre: mined } = useMinedOre();
    const multipliers = useOreMultiplier();
    const suns = useSuns();
    const colonies = useColonies();
    const planets = usePlanets();
    const { balance: alienBalance } = useAlienBalance();
    const perDay = useMemo(() => {
        const alienBlockReward = alienBalance.length * multipliers.alien_multiplier;
        const sunsBlockReward = suns.length * multipliers.sun_multiplier;
        const planetsBlockReward = planets.length * multipliers.planet_multiplier;
        const coloniesMultiplier = colonies.ids.length * multipliers.colony_multiplier;
        const totalBlockReward = alienBlockReward + sunsBlockReward + planetsBlockReward + coloniesMultiplier;
        return totalBlockReward * multipliers.per_block_multiplier * multipliers.blocks_per_day;
    }, [alienBalance, suns, planets, colonies, multipliers]);

    function getValue(value) {
        value = value.toFixed(3);   
        if (window.innerWidth < 768) {
            return abbreviateNumber(value, 1);
        } else return value;
    }

    return (
        <div className={`bg-semitransparent rounded-xl p-4 w-full grid-cols-2 grid sm:grid-cols-3 gap-8 mb-2`}>
            <div>
                <div className={`block md:flex items-center`}>
                    <div className='w-[24px]'>
                        <GiStoneBlock className="hidden sm:block text-xl mr-2"/>
                    </div>
                    <span className='text-3xl font-semibold'>{perDay !== undefined ? getValue(perDay) : 0}<span className='text-sm'>/Day</span></span>
                </div>
                <span className='text-sm leading-tight'>Potential Daily Profitability</span>
            </div>
            <div className='hidden sm:block'>
                <div className={`block md:flex items-center`}>
                    <div className='w-[24px]'>
                        <GiStoneSphere className="hidden sm:block text-xl mr-2"/>
                    </div>
                    <span className='text-3xl font-semibold'>{perDay !== undefined ? getValue(perDay * 30) : 0}<span className='text-sm'>/Month</span></span>
                </div>
                <span className='text-sm leading-tight'>Potential Monthly Profitability</span>
            </div>
            <div>
                <div className={`block md:flex items-center`}>
                    <div className='w-[24px]'>
                        <AiOutlineWallet className="hidden sm:block text-xl mr-2"/>
                    </div>
                    <span className='text-3xl font-semibold'>{mined !== undefined ? getValue(mined) : 0}<span className='text-sm'>ORE</span></span>
                </div>
                <span className='text-sm leading-tight'>Your Total Ore Unrefined</span>
            </div>
        </div>
    )
}

export default CollectionPrediction;