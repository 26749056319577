import React, { useState } from 'react';
import { useAccount, useConnect, useSignMessage, useDisconnect } from 'wagmi';
import useLocalStorage from '../hooks/useLocalStorage';
import axios from 'axios';
import { InjectedConnector } from 'wagmi/connectors/injected';
import config from '../config';

const AuthContext = React.createContext();

export function useAuth() {
    return React.useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { connectAsync } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const { isConnected } = useAccount();
    const { signMessageAsync } = useSignMessage();

    const [user, setUser] = useLocalStorage("user", null);
    const [token, setToken] = useLocalStorage('token', null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
        if (!isAuthenticated) return;
        updateProfile();
    }, [isAuthenticated]);

    React.useEffect(() => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token.replaceAll('"', '')}`;
            setIsAuthenticated(true);
            setLoading(false);
        } else {
            setIsAuthenticated(false);
            setLoading(false);
        }
    }, [token]);

    const updateProfile = async () => {
        const { data } = await axios('/moralis-auth/me', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        });
        setUser(data);
    }

    const authenticate = async ({redirect}) => {
        if (isConnected) {
            await disconnectAsync();
        }

        setLoading(true);

        const { account, chain } = await connectAsync({ connector: new InjectedConnector() });
        const userData = {
            address: account,
            chain: chain.id,
            network: 'evm',
            domain: "mining.lonelyaliens.com",
            authUri: config.baseUrl,
        };

        const { data } = await axios.post('/moralis-auth/request-message', userData);

        const message = data.message;
        // signing the received message via metamask
        const signature = await signMessageAsync({ message });

        try {
            const { data: authData } = await axios.post('/moralis-auth/verify', { message, signature });

            setToken(authData.data.token);
            setUser(authData.data.user);

            if (redirect) {
                window.location.href = redirect;
            }
        }   catch (error) {
            throw error;
        }
    }

    const logout = async () => {
        await disconnectAsync();
        setToken(null);
        setUser(null);
        window.location = "/login";
    }

    return (
        <AuthContext.Provider value={{ user, token, loading, authenticate, logout, isAuthenticated, updateProfile }}>
        {children}
        </AuthContext.Provider>
    );
}