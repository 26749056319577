import React, { useMemo } from 'react';
import DailyPrediction from './DailyPrediction';
import { GiOrbit } from 'react-icons/gi';
import useColonies from '../../hooks/useColonies';
import { useOreMultiplier } from '../../providers/OreMultiplierProvider';

function ColoniesPredictions({ numColonies, colorful=false }) {
  const { colony_multiplier } = useOreMultiplier();
  const colonies = useColonies();
  const colonyHoldings = useMemo(() => {
    let balance = 0;
    balance += colonies.aliens.reduce((acc, alien) => (acc += alien.holds), 0);
    balance += colonies.planets.reduce((acc, planet) => (acc += planet.holds), 0);
    return balance;
  }, [colonies]);
    const perBlock = 69;
    const perDay = 69;
  return (
        <DailyPrediction
            title={"Colonies w/Sun are 2.007"}
            numAssets={colonies.ids.length}
            oreHoldings={colonyHoldings}
            AssetIcon={GiOrbit}
            perBlock={perBlock}
            perDay={perDay}
            multiplier={colony_multiplier}
        />
  )
}

export default ColoniesPredictions;