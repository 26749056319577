import React from 'react';
import { useAlienBalance } from '../providers/AlienBalanceProvider';
import { usePlanetBalance } from '../providers/PlanetBalanceProvider';
import H3 from '../components/H3';
import H2 from '../components/H2';
import ipfs from '../util/ipfs';
import useColonies from '../hooks/useColonies';
import ColoniesPredictions from '../components/predictions/ColoniesPredictions';
import ListPlaceholder from '../components/ListPlaceholder';

function Colonies() {
    const colony = useColonies();
  return (
    <div>
        <ColoniesPredictions numColonies={colony.ids.length}/>
        <div className='mt-12'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid-flow-row'>
            {colony.ids.map((i, id) => {
                const alien = colony.aliens[id];
                const planet = colony.planets[id];
                const alienMeta = alien.metadata;
                const planetMeta = planet.metadata;
                return (
                <div className='card bg-base-200 m-2 shadow-xl' key={i}>
                    <div className='card-body p-2 relative'>
                        <figure className="">
                            {
                                planetMeta && planetMeta.image ?
                                    <img src={ipfs(planetMeta.image)} className="rounded-xl" />
                                    :
                                    <div className='w-full h-full bg-base-300 rounded-xl'></div>
                            }
                        </figure>
                        <div className='absolute left-0 bottom-0 w-[40%]'>
                            {
                                alienMeta && alienMeta.image ?
                                    <img src={ipfs(alienMeta.image)} className="rounded-xl shadow-xl" />
                                    :
                                    <div className='w-full h-full bg-base-300 rounded-xl'></div>
                            }
                        </div>
                        <span className='absolute top-0 left-0 p-6 font-bold text-2xl text-white'>#{i}</span>
                    </div>
                </div>
                )
            })}
            </div>
            {
                colony.ids.length === 0 && (
                    <ListPlaceholder
                        title="No Colonies Found"
                    />
                )
            }
        </div>
    </div>
  )
}

export default Colonies