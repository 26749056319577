import React from 'react';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import useLocalStorage from '../hooks/useLocalStorage';

const AlienBalanceContext = React.createContext();

export function useAlienBalance() {
    return React.useContext(AlienBalanceContext);
}

export function AlienBalanceProvider({ children }) {
    const { isAuthenticated } = useAuth();
    const [balance, setBalance] = useLocalStorage('alienBalance', []);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const reloadBalance = async () => {
        if (!isAuthenticated) return;

        try {
            setLoading(true);
            const { data } = await axios.get(`/api/tokens/my-stake/Aliens`);
            const tokens = data.map((token) => {
                try {
                    token.metadata = JSON.parse(token.metadata);
                } catch {
                    token.metadata = {image: "", attributes: []};
                }
                return token;
            })
            setBalance(tokens);
        } catch (error) {
            // window.location.href = "/login";
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        reloadBalance();
    }, [isAuthenticated]);

    return (
        <AlienBalanceContext.Provider value={{ balance, loading, reloadBalance, error }}>
            {children}
        </AlienBalanceContext.Provider>
    );
}