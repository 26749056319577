import React, { useEffect, useState } from 'react';
import { BiErrorAlt } from 'react-icons/bi';
import { GiLaserWarning, GiAlienStare } from 'react-icons/gi';

const NotificationContext = React.createContext();

export function useNotification() {
  return React.useContext(NotificationContext);
}

function getNotificationBadge(type) {
    if (type === 0 || type === 'error') {
        return <BiErrorAlt className=' w-6 h-6' />;
    } else if (type === 1 || type === 'success') {
        return <GiAlienStare className=' w-6 h-6' />;
    } else if (type === 2 || type === 'warning') {
        return <GiLaserWarning className='w-6 h-6' />;
    }
}

function getClass(type) {
    if (type === 0 || type === 'error') {
        return 'alert-error';
    } else if (type === 1 || type === 'success') {
        return 'alert-success';
    } else if (type === 2 || type === 'warning') {
        return 'alert-warning';
    }
}

export function NotificationProvider({ children }) {
    const [notification, setNotification] = useState(null);
    
    useEffect(() => {
        if (notification) {
        setTimeout(() => {
            setNotification(null);
        }, 5000);
        }
    }, [notification]);
    
    function notify(type, message) {
        setNotification({ type, message });
    }
    
    return (
        <NotificationContext.Provider value={{ notify }}>
        {
            notification && (
                <div className="toast toast-top toast-end z-50 select-none">
                    <div className={`alert ${getClass(notification.type)}`}>
                        <div>
                            {getNotificationBadge(notification.type)}
                            <span className='ml-2 font-semibold'>{notification.message ? notification.message : ""}</span>
                        </div>
                    </div>
                </div>
            )
        }
        {children}
        </NotificationContext.Provider>
    );
}