import React, { useMemo } from 'react';
import DailyPrediction from './DailyPrediction';
import { IoPlanetOutline } from 'react-icons/io5';
import { useOreMultiplier } from '../../providers/OreMultiplierProvider';
import usePlanets from '../../hooks/usePlanets';

function PlanetsPredictinos() {
    const planets = usePlanets();
    const { planet_multiplier } = useOreMultiplier();
    const planetHoldings = useMemo(() => {
        return planets.reduce((acc, planet) => {
            return acc + planet.holds;
        }, 0);
    }, [planets]);
    const perBlock = 69;
    const perDay = 69;
  return (
    <DailyPrediction
        numAssets={planets.length}
        oreHoldings={planetHoldings}
        AssetIcon={IoPlanetOutline}
        perBlock={perBlock}
        perDay={perDay}
        multiplier={planet_multiplier}
    />
  )
}

export default PlanetsPredictinos;