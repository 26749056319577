import React from 'react';
import { Link, Navigate    } from 'react-router-dom';
import { GiMining } from 'react-icons/gi';
import { RiAliensLine } from 'react-icons/ri';
import { IoPlanetSharp } from 'react-icons/io5';
import { GiSundial } from 'react-icons/gi';
import { GiOrbit } from 'react-icons/gi';
import { GiLightBackpack, GiObservatory } from 'react-icons/gi';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { SiStarship } from 'react-icons/si';
import { MdLeaderboard } from 'react-icons/md';

function Drawer({ children }) {
    function close() {
        const drawer = document.querySelector('label[for="drawer"]');
        drawer.click();
    }
  return (
    <div className="drawer drawer-mobile">
        <input id="drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
            { children }
        </div> 
        <div className="drawer-side bg-base-200">
            <label htmlFor="drawer" className="drawer-overlay"></label>
            <ul className="menu w-56 p-2">
                <li className="menu-title">
                    <span>Mothership</span>
                </li>
                <li>
                    <a target="_black" href="https://lonelyaliens.com">
                        <SiStarship className="inline-block w-5 h-5 mr-1" />
                        Mothership
                    </a>
                </li>
                <li className="menu-title">
                    <span>Mining</span>
                </li>
                <li>
                    <Link onClick={close} to="/" htmlFor="drawer">
                        <GiMining className="inline-block w-5 h-5 mr-1" />
                        Profitability
                    </Link>
                </li>
                <li>
                    <Link onClick={close} to="/planets">
                        <IoPlanetSharp className="inline-block w-5 h-5 mr-1" />
                        Planets
                    </Link>
                </li>
                <li>
                    <Link onClick={close} to="/aliens">
                        <RiAliensLine className="inline-block w-5 h-5 mr-1" />
                        Aliens
                    </Link>
                </li>
                <li>
                    <Link onClick={close} to="/suns">
                        <GiSundial className="inline-block w-5 h-5 mr-1" />
                        Suns
                    </Link>
                </li>
                <li>
                    <Link onClick={close} to="/colonies">
                        <GiOrbit className="inline-block w-5 h-5 mr-1" />
                        Colonies
                    </Link>
                </li>
                <li>
                    <Link onClick={close} to="/leaderboard">
                        <MdLeaderboard className="inline-block w-5 h-5 mr-1" />
                        LeadOreBoard
                    </Link>
                </li>

                {/* <li className="menu-title">
                    <span>Observatory</span>
                </li>
                <li>
                    <Link onClick={close} to="/observatory">
                        <GiObservatory className="inline-block w-5 h-5 mr-1" />
                        Observatory
                    </Link>
                </li> */}

                <li className="menu-title">
                    <span>Ification Machine</span>
                </li>
                <li>
                    <Link onClick={close} to="/art">
                        <GiLightBackpack className="inline-block w-5 h-5 mr-1" />
                        Art
                    </Link>
                </li>

                <li className="menu-title">
                    <span>Account</span>
                </li>
                <li>
                    <Link onClick={close} to="/wallet">
                        <MdAccountBalanceWallet className='text-xl'/>
                        Wallet
                    </Link>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Drawer;