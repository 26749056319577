import React from 'react';
import ipfs from '../util/ipfs';

function Listings({ listings }) {
  return (
    <div className='grid grid-cols-2 gap-12'>
        {
            listings.map((listing, i) => (
                <div key={i} className="card card-side bg-base-100 shadow-xl">
                    <figure><img src={ipfs("")} alt="Movie"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">New movie is released!</h2>
                        <p>Click the button to watch on Jetflix app.</p>
                        <div className="card-actions justify-end">
                        <button className="btn btn-primary">Watch</button>
                        </div>
                    </div>
                </div>
            ))
        }
    </div>
  )
}

export default Listings