import React from 'react';
import { useAuth } from '../providers/AuthProvider';
import { Navigate, Outlet } from 'react-router-dom';

function OnlyLoggedIn() {
  const { isAuthenticated, loading } = useAuth();
  if (!isAuthenticated && !loading) {
    return <Navigate to="/login" />;
  }

  return (
    <Outlet/>
  )

}

export default OnlyLoggedIn