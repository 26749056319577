import React, { useMemo } from 'react'
import DailyPrediction from './DailyPrediction'
import { GiSundial } from 'react-icons/gi'
import useSuns from '../../hooks/useSuns'
import { useOreMultiplier } from '../../providers/OreMultiplierProvider';

function SunsPredictions() {
  const { sun_multiplier, planet_multiplier } = useOreMultiplier();
  const multiplier = sun_multiplier + planet_multiplier;
    const suns = useSuns();
    const sunHoldings = useMemo(() => {
        return suns.reduce((acc, sun) => {
            return acc + sun.holds;
        }, 0);
    } ,[suns]);
  return (
    <DailyPrediction
        numAssets={suns.length}
        oreHoldings={sunHoldings}
        AssetIcon={GiSundial}
        multiplier={multiplier}
    />
  )
}

export default SunsPredictions;