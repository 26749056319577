import React from 'react';
import Container from '../components/Container';
import { Outlet } from 'react-router-dom';
import BalanceSummary from '../components/BalanceSummary';
import Dashboard from '../components/Dashboard';
import LicenseAgreement from '../components/LicenseAgreement';

function Home() {
  return (
    <div>
      <Container>
          <Dashboard/>
          <LicenseAgreement/>
          <BalanceSummary/>
          <Outlet/>
      </Container>
    </div>
  )
}

export default Home;