import React, { useState, useEffect } from 'react';
import Canvas from './Canvas';
import Canvas4GIF from './Canvas4GIF';
import LayerOptionSelector from './LayerOptionSelector';
import ipfs from '../../util/ipfs';
import config from '../../config';

const black_holes = ['2166', '8719'];

function selectInitialLayers(theme, token, useAvatar) {
  if(!token) return <></>;
  let alien;
  let alienMeta;
  let useTheme = true;
  try {
    alienMeta = token.metadata
    if (useAvatar && token.themed) {
      alien = token.themed.url;
      useTheme = false;
    }
    else {
      alien = ipfs(alienMeta.image);
    }
  } catch {
    alien = ""; // TODO: add placeholder
  }

  //check for black holes
  if ( black_holes.includes(token.token_id) ) {
    const body = alienMeta.attributes.find((attr) => (attr.trait_type.toUpperCase().replaceAll(' ', "_") === "BODY"));
    body.value = "BLACK HOLE";
  }

  const layers = theme === -1 || !useTheme ? "" : theme.layers.map((layer) => {
    if (layer.isSelectable) {
      return layer.variant[0].image.url;
    } else {
      try {
        const traitName = layer.trait_name;
        const traitValueToMatch = alienMeta.attributes.find((attr) => (attr.trait_type.toUpperCase().replaceAll(' ', "_") === traitName.toUpperCase().replaceAll(' ', "_"))).value;
        const variant = layer.variant.find((variant) => (variant.trait_value.toUpperCase().replaceAll(' ', "_") === traitValueToMatch.toUpperCase().replaceAll(' ', "_")));
        return variant.image.url;
      } catch {
        return "";
      }
    }
  });

  return [[alien, ...layers], useTheme];
}

function GenerateArt({ theme, token, consumeArt=()=>{}, width, useAvatar=false }) {
  const [layers, setLayers] = useState([]);
  const [useTheme, setUseTheme] = useState(true);

  useEffect(() => {
    if (!theme) return;
    if (!token) return;
    const [_layers, _useTheme] = selectInitialLayers(theme, token, useAvatar);
    setLayers(_layers);
    setUseTheme(_useTheme);
  }, [theme, token]);

  const selectLayer = (i, src) => {
    let newLayers = [...layers];
    newLayers[i+1] = src;
    setLayers(newLayers);
  }

  if (!token) return <></>;

  return (
    <div>
      <div className='grid grid-cols-6'>
        {/* Index change to index of this shit in the global theme object */}
        {
          theme && theme !== -1 && theme.layers.map((layer, i) =>{
            if (layer.isSelectable) {
              return (
                <div className='col-span-2' key={i}>
                  <LayerOptionSelector
                    layer={layer}
                    selectLayer={selectLayer}
                    index={i}
                  />
                </div>
              )
            } else {
              return null;
            }
          })
        }
      </div>
      <Canvas4GIF
          layers={layers}
          consumeArt={consumeArt}
          totalLayers={theme && theme !== -1 && useTheme ? theme.layers.length+1 : 1}
          width={width}
        />
    </div>
  )
}

export default GenerateArt;